import React, { useContext, useEffect, Dispatch, SetStateAction } from "react";
import { MemorialTab } from "../AppType";
import { ASSET_URL } from "../../utils/AppUtil";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ModalAccountDrawer } from "../Modals/ModalAccountDrawer";
import { ModalMemorialImages } from "../Modals/ModalMemorialImages";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";

gsap.registerPlugin(ScrollTrigger);

type HeaderProps = {
    activeTab: MemorialTab,
    setActiveTab: Dispatch<SetStateAction<MemorialTab>>,
    isActiveTab:  (pageId: MemorialTab) => number,
}

export const Header: React.FC<HeaderProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    let profilePictureSrc = "images/fl-avatar-min.svg";

    let backgroundStyle: React.CSSProperties = {
        backgroundImage: "linear-gradient(180deg, rgba(0, 34, 47, 0.8) 0%, #00222F 100%), url('/images/covor_photo_default_Small.png')"
    }

    if (memorialData.memorialPictureId) {
        profilePictureSrc = `${ASSET_URL}/public/picture/${memorialData.memorialPictureId}`;
    }

    if (memorialData.coverPictureId) {
        backgroundStyle = {
            backgroundImage: `linear-gradient(180deg, rgba(0, 34, 47, 0.8) 0%, #00222F 100%), url(${ASSET_URL}/public/picture/${memorialData.coverPictureId})`
        }
    }
    
    useEffect(() => {

    }, [memorialData]);

    useEffect(() => {

        // Move and shrink profile section
        gsap.to(".profile-container", {
            y: -114,
            x: -55,
            scale: 0.26,
            ease: "none",
            scrollTrigger: {
                trigger: ".memorial-header",
                start: '1px',
                end: '170px',
                scrub: 0
            }
        });

        if (memorialData.role === "CREATOR") {
            
            // Fade profile image add button
            gsap.to(".profile-add", {
                opacity: 0,
                ease: "none",
                immediateRender: false,
                scrollTrigger: {
                    trigger: ".memorial-header",
                    start: '1px',
                    end: '80px',
                    scrub: 0
                }
            });
        }

        // Fade profile dates text
        gsap.to(".profile-dates", {
            opacity: 0,
            ease: "none",
            immediateRender: false,
            scrollTrigger: {
                trigger: ".memorial-header",
                start: '1px',
                end: '40px',
                scrub: 0
            }
        });

        if (memorialData.role === "CREATOR" || memorialData.role === "EDITOR") {
        
            // Fade memorial share button
            gsap.to(".profile-share", {
                opacity: 0,
                ease: "none",
                immediateRender: false,
                scrollTrigger: {
                    trigger: ".memorial-header",
                    start: '1px',
                    end: '80px',
                    scrub: 0
                }
            });
        }

        // Enlarge profile name
        gsap.to(".profile-name", {
            y: 16,
            x: 80,
            scale: 2,
            ease: "none",
            immediateRender: false,
            scrollTrigger: {
                trigger: ".memorial-header",
                start: '1px',
                end: '150px',
                scrub: 0
            }
        });

        // Background colour change
        gsap.to(".menu-container", {
            backgroundColor: "#001B26",
            immediateRender: false,
            scrollTrigger: {
                trigger: ".memorial-header",
                start: '120px',
                scrub: 0
            }
        });
        
    }, [memorialData.role]);

    const handlePageClick = (pageId: MemorialTab) => {
        props.setActiveTab(pageId);
    }

    return (
        <>
            <div className="header-bg" style={backgroundStyle}>
                <div className="memorial-header d-flex flex-column gap-4">
                    <div className="menu-container d-flex justify-content-between p-3">
                        <Link to={"/"} className="btn-wrapper" onClick={ () => { handlePageClick(MemorialTab.home)} }>
                            <img src="/images/modal-back-chev.svg" alt="" />
                        </Link>

                        <div className="d-flex gap-3 align-items-center">
                            <div className="btn-wrapper">
                                <img src="/images/fl-share-thick.svg" alt="" />
                            </div>
                            <ModalAccountDrawer />
                        </div>
                    </div>

                    <div className="d-flex flex-column" style={{height: 148}}>
                        <div className="profile-container d-flex gap-4 mb-2" style={{minWidth: "307px"}}>

                            {/* Avatar start */}
                            <div className="profile-image-container">
                                {/* {(memorialData.role === "CREATOR" || memorialData.role === "EDITOR") && */}
                                    <ModalMemorialImages />
                                {/* } */}
                                <div className="memorial-profile-picture-wrapper">
                                    <img className="memorial-profile-picture" src={profilePictureSrc} width={144} height={144} style={{background: "#00222F"}} alt="" />
                                </div>
                            </div>
                            {/* Avatar end */}

                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex flex-column gap-2">
                                    <p className="profile-name fl-txt-name">{ memorialData.firstName }<br />{ memorialData.lastName }</p>
                                    <p className="profile-dates fl-txt-date">{ `${memorialData.birthYear + " — " + memorialData.deathYear}` }</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* <div className="d-flex px-3 pb-3">
                    <div className="community-pill d-flex gap-2 align-items-center p-2 pe-3">
                        <img src="/images/fl-btn-community-add.svg" />
                        <p className="fl-txt-btn">{ t("Header.addCommunity", {name: `${memorialData.firstName}`, ns: "components"}) }</p>
                    </div>
                </div> */}
                
            </div>
        </>
    );
}