import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { CurrencyType, GiftDonateType, GiftType } from "../AppType";
import { Modal } from "react-bootstrap";
import { ASSET_URL, fetchRecords, postFormData } from "../../utils/AppUtil";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';


export const ModalVerifyAccount: React.FC = () => {
    
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Refs
    const refs = useRef<(HTMLDivElement | null)[]>([]);

    // States
    const [formStep, setFormStep] = useState<number>(0);
    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalFail, setModalFail] = useState<string>("");
    const [isTransitioning, setIsTransitioning] = useState<Boolean>(false);
    
    

    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (show: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        // Define a ref to store the starting X position of the touch
        const startX = useRef<number | null>(null);
    
        useEffect(() => {
            const handleTouchStart = (e: TouchEvent) => {
                if (modalRef.current) {
                    startX.current = e.touches[0].clientX;
                }
            };
    
            const handleTouchMove = (e: TouchEvent) => {
                if (modalRef.current && startX.current !== null) {
                    const modal = modalRef.current;
                    const touchX = e.touches[0].clientX;
                    const deltaX = startX.current - touchX;
    
                    const canScrollLeft = modal.scrollLeft > 0;
                    const canScrollRight = modal.scrollWidth > modal.clientWidth + modal.scrollLeft;
    
                    if (modal.contains(e.target as Node)) {
                        // Scrolling inside the modalRef div
                        if ((deltaX > 0 && !canScrollRight) || (deltaX < 0 && !canScrollLeft)) {
                            e.preventDefault(); // Prevent scrolling outside the modalRef div
                        }
                    } else {
                        // Scrolling outside the modalRef div
                        e.preventDefault(); // Prevent scrolling the entire modal
                    }
    
                    startX.current = touchX; // Update startX for continuous movement
                }
            };
    
            if (show) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchstart', handleTouchStart, { passive: true });
                window.addEventListener('touchmove', handleTouchMove, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchmove', handleTouchMove);
            }
    
            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchmove', handleTouchMove);
            };
        }, [show, modalRef]); // Dependencies for the useEffect hook
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(show, modalRef);

    // On modal show
    const handleShow = () => {
        setShow(true);
        setFormStep(0);
    }

    // On modal hide
    const handleClose = () => {
        setShow(false);
    }

    // Progress to next step
    const handleClickNext = () => {
        setFormStep(formStep + 1);
    }

    // Progress to previous step
    const handleClickBack = () => {
        setFormStep(formStep - 1);
        
        setIsTransitioning(true);
        setTimeout(() => {
            setIsTransitioning(false);
        }, 500);
    }

    // Set formState on input updates
    // const handleFormChange = (e: React.ChangeEvent<any>) => {
    //     setFormState(() => ({
    //         ...formState,
    //         [e.target.name]: e.target.value
    //     }));
    // }

    return (
        <>
            <div className="my-account-tile" onClick={handleShow}>
                <img src="images/img-help-and-support.svg" />
                <p>Help & Support</p>
            </div>

            {/* Modal content */}
            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>

                <Modal.Header>
                    {formStep === 0 ?
                        <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClose}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                        :
                        <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClickBack}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                    }
                    <p className="modal-title">foreverloved</p>
                    <div className="btn btn-modal-close" onClick={handleClose} style={{zIndex: 999}}><img src="/images/modal-close-x.svg"/></div>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-progress form-progress-absolute">
                        <img src={`/images/fl-progress-verify.svg`} className={`form-progress-current form-progress-${formStep}`} alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                    </div>

                    <div className="form-container">

                        <div className={`form-screen form-content px-3 form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2 mb-4">
                                <p className="modal-heading">{commonData.firstName}, please verify<br/>your account</p>
                                <p className="modal-subheading">We’ll send a confirmation code to your phone</p>
                            </div>

                            <div className="login-form">
                                <label htmlFor="firstname" className="form-label">Mobile number</label>
                                <div className="d-flex gap-2">
                                    <select id="gender" name="gender" className="form-select" style={{width: "auto", backgroundPositionX: "80%"}}>
                                        <option value="" className="select-option" selected disabled>Flag</option>
                                        <option className="select-option">Flag</option>
                                        <option className="select-option">Flag</option>
                                        <option className="select-option">Flag</option>
                                    </select>

                                    <input id="mobileNumber" name="mobileNumber" type="text" className="form-control" placeholder="+44" required />
                                </div>
                            </div>
                        </div>

                        <div className={`form-screen form-content px-3 form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2 mb-4">
                                <p className="modal-heading">Enter your code</p>
                                <p className="modal-subheading">Check your phone for a code from us</p>
                            </div>

                            <div className="login-form">
                                <div className="d-flex justify-content-center gap-3 mb-4">
                                    <input id="codeNum1" name="codeNum1" type="text" className="form-control" required />
                                    <input id="codeNum2" name="codeNum2" type="text" className="form-control" required />
                                    <input id="codeNum3" name="codeNum3" type="text" className="form-control" required />
                                    <input id="codeNum4" name="codeNum4" type="text" className="form-control" required />
                                </div>

                                <div className="d-flex justify-content-center">
                                    <p className="resend-code-txt">Didn't receive a code? <span>Resend it</span></p>
                                </div>
                            </div>

                            
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-modal-footer" onClick={handleClickNext}>Send code</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}





