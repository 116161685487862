import React, { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { debug, fetchRecords, postFormData } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import { addRelativeType, familyTreeGetType, familyTreeNode, familyTreePostType } from "../AppType";
import { AddRelativePopup } from "./AddRelativePopup";
import { DeceasedPlaceholderPopup } from "./DeceasedPlaceholderPopup";
import { LivingPlaceholderPopup } from "./LivingPlaceholderPopup";
import { ReactFlow, Background, Controls, applyEdgeChanges, applyNodeChanges, addEdge, Position, BackgroundVariant, Edge, NodeChange } from '@xyflow/react';
import { TreeNode } from "./TreeNode";
import '@xyflow/react/dist/style.css';
import "animate.css"
import { DetailedNode } from "./DetailedNode";
import { DefaultNode } from "./DefaultNode";
import { LinkMemorial } from "./LinkMemorial";

export const ModalFamilyTree: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>("modal-deactivate-scroll");
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalSuccessAnimation, setModalSuccessAnimation] = useState<string>('animate__animated animate__slideInRight');

    const [deceasedRelativePopupOpen, setDeceasedRelativePopupOpen] = useState<boolean>(false);
    const [livingRelativePopupOpen, setLivingRelativePopupOpen] = useState<boolean>(false);

    const [addRelativePopup, setAddRelativePopup] = useState<boolean>(false);
    const [addRelativePopupName, setAddRelativePopupName] = useState<string>('');
    const [addRelativePosX, setAddRelativePosX] = useState<number>(0);
    const [addRelativePosY, setAddRelativePosY] = useState<number>(0);
    const [addRelativeFrom, setAddRelativeFrom] = useState<string>('');

    const [detailedNodeActive, setDetailedNodeActive] = useState<boolean>(false);
    const [detailedNodeFirstName, setDetailedNodeFirstName] = useState<string>("");
    const [detailedNodeLastName, setDetailedNodeLastName] = useState<string>("");
    const [detailedNodeRelation, setDetailedNodeRelation] = useState<string>("");
    const [detailedNodeId, setDetailedNodeId] = useState<string>("");
    const [detailedNodePosX, setDetailedNodePosX] = useState<number>(0);
    const [detailedNodePosY, setDetailedNodePosY] = useState<number>(0);
    const [detailedNodeType, setDetailedNodeType] = useState<string>("");

    const [selectedRelative, setSelectedRelative] = useState<string>('');
    const [newRelative, setNewRelative] = useState<string>('');
    const [selectedTileName, setSelectedTileName] = useState<string>('');
    const [tileSelected, setTileSelected] = useState<boolean>(false);
    const [hasPassed, setHasPassed] = useState<boolean>(false);

    const [tileSelectedAnimation, setTileSelectedAnimation] = useState<string>('');

    const [popupAnimation, setPopupAnimation] = useState<string>('');
    const [formStep, setFormStep] = useState<number>(0);

    const [pointerEvents, setPointerEvents] = useState<'all' | 'none'>('all');

    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    const [draggedNodePositions, setDraggedNodePositions] = useState<Record<string, { x: number, y: number }>>({});
    const [dirty, setDirty] = useState<boolean>(false);

    const [linkMemorialOpen, setLinkMemorialOpen] = useState<boolean>(false);


    // Ref to store the initial Y position of the node when it's grabbed
    const initialYRef = useRef<number>(0);

    const formData: familyTreePostType = {
        userId: "",
        memorialId: "",
        proxyFirstName: "",
        proxyLastName: "",
        relationship: "",
        proxyType: "",
        data: {
            posX: 0,
            posY: 0,
            handleTo: "",
            handleFrom: "",
            fromTreeNodeId: ""
        }
    }
    const [formState, setFormState] = useState<familyTreePostType>(formData);

    useEffect(() => {

    }, [dirty]);

    const handleAddRelativePopup = (firstName: string, posX: number, posY: number, relationFrom: string, fromTreeNodeId: string) => {
        setAddRelativePopupName(firstName);
        setAddRelativePosX(posX);
        setAddRelativePosY(posY);
        setAddRelativeFrom(relationFrom);
        setAddRelativePopup(true);

        setFormState((formState) => ({
            ...formState,
            data: {
                ...formState.data,
                fromTreeNodeId: fromTreeNodeId
            }
        }));
    }

    const handleDetailedNode = (firstName: string, lastName: string, relation: string, id: string, posX: number, posY: number, displayType: string) => {
        setDetailedNodeFirstName(firstName);
        setDetailedNodeLastName(lastName);
        setDetailedNodeRelation(relation);
        setDetailedNodeId(id);
        setDetailedNodePosX(posX);
        setDetailedNodePosY(posY);
        setDetailedNodeType(displayType)
        setDetailedNodeActive(true);
    }

    const handleAddRelative = (relation: string, addPosY: number, handleFrom: string, handleTo: string) => {
        setSelectedRelative(relation);
        setNewRelative(relation);

        setFormState((formState) => ({
            ...formState, 
            relationship: relation,
            data: {
                ...formState.data,
                handleFrom: handleFrom,
                handleTo: handleTo
            }
        }));

        const newPosY: number = addRelativePosY + addPosY;

        setFormState((formState) => ({
            ...formState, 
            data: {
                ...formState.data,
                posX: addRelativePosX,
                posY: newPosY
            }
        }));

        setTimeout(() => {
            setFormStep(1);
        }, 1000);
    }

    const handleAddFatherMother = (id: string, posX: number, posY: number) => {

        let newRelative: string;
        if (id === 'addFatherNode') {
            newRelative = 'Father'
        } else {
            newRelative = 'Mother'
        }

        setSelectedRelative(newRelative);
        setAddRelativePopupName(commonData.firstName);

        setFormState((formState) => ({
            ...formState, 
            relationship: 'Father',
            data: {
                ...formState.data,
                handleFrom: 'top',
                handleTo: newRelative === 'Father' ? 'right' : 'left',
                posX: posX,
                posY: posY,
                fromTreeNodeId: 'originNode'
            }
        }));

        setFormStep(1);
    }

    const nodeTypes = {node: TreeNode, defaultNode: DefaultNode};

    const initialNodes = [
        {
            id: 'originNode',
            position: { x: 0, y: 0 },
            type: 'node',
            draggable: false,
            data: {
                id: 'node',
                position: { x: 0, y: 0 },

                displayType: 'origin',
                firstName: commonData.firstName,
                lastName: commonData.lastName,
                relation: 'You',

                targetHandle: Position.Bottom,
                sourceHandleTop: Position.Top,
                sourceHandleRight: Position.Right,
                sourceHandleBottom: Position.Bottom,
                sourceHandleLeft: Position.Left,

                targetHandleId: 'target-originNode',
                sourceHandleTopId: 'source-top-originNode',
                sourceHandleRightId: 'source-right-originNode',
                sourceHandleBottomId: 'source-bottom-originNode',
                sourceHandleLeftId: 'source-left-originNode',

                extensionPos: 'none',
                handleExtentionClick: handleAddRelativePopup,
                handleNodeClick: handleDetailedNode
            }
        },
        {
            id: 'addFatherNode',
            position: { x: -82, y: -200 },
            type: 'defaultNode',
            draggable: false,
            data: {
                id: 'addFatherNode',
                position: { x: -82, y: -200 },

                displayType: 'initial',
                firstName: 'Add',
                lastName: 'Father',
                relation: '',

                targetHandle: Position.Right,
                sourceHandleTop: Position.Top,
                sourceHandleRight: Position.Right,
                sourceHandleBottom: Position.Bottom,
                sourceHandleLeft: Position.Left,

                targetHandleId: 'target-addFatherNode',
                sourceHandleTopId: 'source-top-addFatherNode',
                sourceHandleRightId: 'source-right-addFatherNode',
                sourceHandleBottomId: 'source-bottom-addFatherNode',
                sourceHandleLeftId: 'source-left-addFatherNode',

                extensionPos: 'none',
                handleExtentionClick: handleAddRelativePopup,
                handleNodeClick: handleAddFatherMother
            }
        },
        {
            id: 'addMotherNode',
            position: { x: 82, y: -200 },
            type: 'defaultNode',
            draggable: false,
            data: {
                id: 'addMotherNode',
                position: { x: 82, y: -200 },

                displayType: 'initial',
                firstName: 'Add',
                lastName: 'Mother',
                relation: '',

                targetHandle: Position.Left,
                sourceHandleTop: Position.Top,
                sourceHandleRight: Position.Right,
                sourceHandleBottom: Position.Bottom,
                sourceHandleLeft: Position.Left,

                targetHandleId: 'target-addMotherNode',
                sourceHandleTopId: 'source-top-addMotherNode',
                sourceHandleRightId: 'source-right-addMotherNode',
                sourceHandleBottomId: 'source-bottom-addMotherNode',
                sourceHandleLeftId: 'source-left-addMotherNode',

                extensionPos: 'up',
                handleExtentionClick: handleAddRelativePopup,
                handleNodeClick: handleAddFatherMother
            }
        },
    ];

    const [nodes, setNodes] = useState<familyTreeNode[]>(initialNodes);

    const initialEdges = [
        {
            id: 'originNode->addFatherNode',
            source: 'originNode',
            target: 'addFatherNode',
            sourceHandle: 'source-top-originNode',
            targetHandle: 'target-addFatherNode',
            type: 'step',
            style: { stroke: '#1A323C', strokeWidth: 2 }
        },
        {
            
            id: 'originNode->addMotherNode',
            source: 'originNode',
            target: 'addMotherNode',
            sourceHandle: 'source-top-originNode',
            targetHandle: 'target-addMotherNode',
            type: 'step',
            style: { stroke: '#1A323C', strokeWidth: 2 }
        }
    ];

    const [edges, setEdges] = useState<Edge[]>(initialEdges);

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (show) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
    }, [show]);

    // Disable DOM zooming to allow pinching on image
    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    // Get family tree data
    useEffect(() => {
        fetchRecords(commonData.token, 'user/loggedIn/familyTree', processFamilyTreeData)
        .catch((error) => {
            debug('fetchRecords error: ' + error, 'FamilyTree');
        });
    }, [deceasedRelativePopupOpen, livingRelativePopupOpen, refreshCounter, dirty]);

    const processFamilyTreeData = (responseJson: familyTreeGetType[]) => {
        setNodes(prevNodes => [
            ...prevNodes, 
            ...buildFamilyTreeNodes(responseJson)
        ]);

        setEdges(prevEdges => [
            ...prevEdges,
            ...buildFamilyTreeEdges(responseJson)
        ]);
    }

    const buildFamilyTreeNodes = (response: familyTreeGetType[]): any[] => {
        let key: number = 0;

        const result: any[] = [];

        let displayType: string;
        let firstName: string;
        let lastName: string;


        response.forEach((node: familyTreeGetType) => {
            
            if (node.user) {
                displayType = 'user';
                firstName = node.user.firstName;
                lastName = node.user.lastName;

            } else if (node.memorial) {
                displayType = 'memorial';
                firstName = node.memorial.firstName;
                lastName = node.memorial.lastName;

            } else if (node.proxyType) {
                displayType = 'proxy';
                firstName = node.proxyFirstName;
                lastName = node.proxyLastName;
            }

            result.push(
                {
                    key: key,
                    id: node.treeNodeId,
                    position: { x: node.data?.posX, y: node.data?.posY },
                    type: 'node',
                    draggable: true,
                    data: {
                        id: node.treeNodeId,
                        position: { x: node.data?.posX, y: node.data?.posY },

                        displayType: displayType,
                        firstName: firstName,
                        lastName: lastName,
                        relation: node.relationship,

                        targetHandle: node.data?.handleTo,
                        sourceHandleTop: Position.Top,
                        sourceHandleRight: Position.Right,
                        sourceHandleBottom: Position.Bottom,
                        sourceHandleLeft: Position.Left,

                        targetHandleId: `target-${node.treeNodeId}`,
                        sourceHandleTopId: `source-top-${node.treeNodeId}`,
                        sourceHandleRightId: `source-right-${node.treeNodeId}`,
                        sourceHandleBottomId: `source-bottom-${node.treeNodeId}`,
                        sourceHandleLeftId: `source-left-${node.treeNodeId}`,

                        extensionPos: (node.data?.posY === 0 ? 'none' : (node.data?.posY < 0 ? 'up' : 'down')),
                        handleExtentionClick: handleAddRelativePopup,
                        handleNodeClick: handleDetailedNode
                    }
                }
            );

            key ++;
        });

        return result;
    }

    const buildFamilyTreeEdges = (response: familyTreeGetType[]): any[] => {
        let key: number =   0;
        const result: any[] = [];

        response.forEach((edge: familyTreeGetType) => {
            result.push({
                key: key,
                id: `${edge.data?.fromTreeNodeId}->${edge.treeNodeId}`,
                source: edge.data?.fromTreeNodeId,
                target: edge.treeNodeId,
                sourceHandle: `source-${edge.data?.handleFrom}-${edge.data?.fromTreeNodeId}`,
                targetHandle: `target-${edge.treeNodeId}`,
                type: 'step',
                style: { stroke: '#1A323C', strokeWidth: 2 }
            });

            key ++;
        });

        return result;
    }

    const onNodesChange = useCallback(
        (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes],
    );

    const onEdgesChange = useCallback(
        (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setNodes],
    );

    const onConnect = useCallback(
        (params: any) => setEdges((eds) => addEdge(params, eds)),
        [setNodes],
    );

    // Handle the start of dragging
    const handleNodeDragStart = useCallback((event: React.MouseEvent, node: familyTreeNode) => {

        // Capture the Y position of the node when dragging starts and store it in the ref
        initialYRef.current = node.position.y;
        console.log("Node grabbed. Y position captured:", node.position.y);
    }, []);

    const handleNodesChange = useCallback((changes: NodeChange[]) => {
        setNodes((nds) =>
        applyNodeChanges(
            changes.map((change) => {
                if (change.type === 'position') {
                    const node = nds.find((n) => n.id === change.id);
                    if (node) {
                    return {
                        ...change,
                        type: "position", // Set to the literal "position" as required
                        position: { x: change.position?.x ?? node.position.x, y: initialYRef.current },
                    };
                    }
                }

                return change;
            }),
            nds
        ) as familyTreeNode[]
        );
    }, []);

    // Handle node drag stop
    const handleNodeDragStopOLD = useCallback((event: React.MouseEvent, node: familyTreeNode) => {
        
        // Capture the new X position and keep the Y position unchanged
        const newPosition = {
            x: node.position.x, // update X position
            y: initialYRef.current, // keep Y position the same
        };

        // Log the updated position (only X should change)
        console.log('New X position:', newPosition.x);
        console.log('Y position is locked:', newPosition.y);

        // Update state with the new position (keeping Y fixed)
        setNodes((prevNodes) =>
            prevNodes.map((n) =>
                n.id === node.id ? { ...n, position: newPosition } : n
            )
        );

        const formDataReposition = {
            posX: Math.ceil(node.position.x),
            posY: initialYRef.current,
        }

        postFormData(
            commonData.token,
            `user/loggedIn/familyTree/${node.id}/data`,
            formDataReposition,
            (response: any) => {
                console.log("data sent! ----->", response);
            },
            (error: any) => { // error
                console.log("oops! ----->", error);
            }
        );
        
    }, []);

    // Handle node dragging and edge updates during drag
    const handleNodeDrag = useCallback((event: React.MouseEvent, node: familyTreeNode) => {

        // Restrict Y-axis position during the drag (keep it fixed)
        const updatedPosition = {
            x: node.position.x,  // Update X-axis
            y: initialYRef.current // Keep Y-axis fixed
        };

        // Update draggedNodePositions immediately during the drag
        setDraggedNodePositions(prev => ({
            ...prev,
            [node.id]: updatedPosition // Real-time update for node position (X-axis only)
        }));

    }, [edges, nodes]);

    // Handle node drag stop (finalize position)
    const handleNodeDragStop = useCallback((event: React.MouseEvent, node: familyTreeNode) => {
        const connectedEdge = edges.find(edge => edge.source === node.id || edge.target === node.id);

        let newHandlePosition: string = '';

        if (connectedEdge) {
            const connectedNodeId = connectedEdge.source === node.id ? connectedEdge.target : connectedEdge.source;
            const connectedNode = nodes.find(n => n.id === connectedNodeId);

            if (connectedNode) {
                const isLeft = node.position.x < connectedNode.position.x;
                const isRight = node.position.x > connectedNode.position.x;

                if (isLeft) newHandlePosition = 'right';
                else if (isRight) newHandlePosition = 'left';

                console.log('Updated Handle Position:', newHandlePosition);
            }
        }

        const formDataReposition = {
            posX: Math.ceil(node.position.x),
            posY: initialYRef.current,
            handleTo: newHandlePosition
        };

        postFormData(
            commonData.token,
            `user/loggedIn/familyTree/${node.id}/data`,
            formDataReposition,
            (response: any) => console.log("Data sent!", response),
            (error: any) => console.log("Oops! Error:", error)
        );

        setRefreshCounter(c => c + 1);

        setDirty(c => !c);

    }, [edges, nodes]);


    const handleShow = () => {
        setShow(true);
        setFormStep(0);
    }

    const handleClose = () => {
        setShow(false);
        setFormStep(0);
    }

    const handleClickBack = () => {
        if (formStep !== 0) {
            setFormStep(formStep - 1);
        } else {
            handleClose();
        }        
    }

    const handleCloseSelectedTile = () => {
        setTileSelectedAnimation('animate__animated animate__fadeOut animate__faster');

        setTimeout(() => {
            setTileSelected(false);
        }, 300);
    }

    const handleFormChange = (e: React.ChangeEvent<any>) => {
        if (e.target.type === "text") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));
        }
    }

    const handleHasPassed = (passed: boolean) => {
        setHasPassed(passed);
        let proxyType: string;
        if (passed) {
            proxyType = 'MEMORIAL'
        } else {
            proxyType = 'USER'
        }

        setFormState((formState) => ({
            ...formState, 
            'proxyType': proxyType
        }));
    }

    const handleConfirmRelative = () => {
        setModalSuccess(true);
        setFormState(formData);

        setTimeout(() => {
            setFormStep(0);
            setSelectedRelative('');
        }, 1000);

        setTimeout(() => {
            setModalSuccessAnimation('animate__animated animate__slideOutRight');
        }, 2000);

        setTimeout(() => {
            setModalSuccess(false);
            setModalSuccessAnimation('');
            setDeceasedRelativePopupOpen(true);
        }, 3000);
    }

    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        postFormData(
            commonData.token,
            `user/loggedIn/familyTree`,
            [formState],
            (response: any) => {
                handleConfirmRelative();
            },
            (error: any) => { // error

            }
        );
    }

    return (
        <>
            <div className="btn fl-btn-dark w-100 mt-2" onClick={handleShow}>Start</div>

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <div className={`btn-modal-back`} onClick={handleClickBack}>
                        <img src="/images/modal-back-chev.svg" />
                    </div>

                    <p className="modal-title">Heading</p>
                    
                    <div className={`btn btn-modal-close`} onClick={handleClose} >
                        <img src="/images/modal-close-x.svg"/>
                    </div>
                </Modal.Header>

                <Modal.Body className="p-0">
                    <div className={`family-tree-wrapper form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')} justify-content-center`}>

                        <ReactFlow
                            nodes={nodes.map(node => ({
                                ...node,
                                position: draggedNodePositions[node.id] || node.position // Use updated position from draggedNodePositions
                            }))}
                            edges={edges}
                            onNodesChange={handleNodesChange}
                            onEdgesChange={onEdgesChange}
                            onNodeDragStart={handleNodeDragStart} // Capture Y position when dragging starts
                            onNodeDragStop={handleNodeDragStop}
                            onNodeDrag={handleNodeDrag}  // Track node movement during drag
                            onConnect={onConnect}
                            nodeTypes={nodeTypes}
                            fitView
                        >
                            <Background variant={BackgroundVariant.Dots} />
                            {/* <Controls /> */}
                        </ReactFlow>

                    </div>

                    <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')} p-4`}>
                        
                        {selectedRelative !== '' &&
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Tell us about {addRelativePopupName}'s {selectedRelative}</p>
                                </div>

                                <div className="login-form">
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">First name</label>
                                        <input id="proxyFirstName" name="proxyFirstName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter their first name" required />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">Last name</label>
                                        <input id="proxyLastName" name="proxyLastName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter their last name" required />
                                    </div>

                                    <label htmlFor="email" className="form-label">Have they passed away?</label>
                                    <div className="d-flex gap-3">
                                        <div className="create-memorial-radio-wrapper d-flex align-items-center gap-3" onClick={() => handleHasPassed(true)}>
                                            <div className={`create-memorial-radio ${hasPassed && 'selected'}`}>
                                                <div className="radio-selected"></div>
                                            </div>
                                            <p>Yes</p>
                                        </div>

                                        <div className="create-memorial-radio-wrapper d-flex align-items-center gap-3" onClick={() => handleHasPassed(false)}>
                                            <div className={`create-memorial-radio ${!hasPassed && 'selected'}`}>
                                                <div className="radio-selected"></div>
                                            </div>
                                            <p>No</p>
                                        </div>
                                    </div>

                                    <button onClick={handlePost} className="btn fl-btn-modal-bottom button-absolute">Done</button>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>

                {modalSuccess && 
                    <div className={`modal-success-div ${modalSuccessAnimation}`} style={{zIndex: 999}}>
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>{formState.proxyFirstName} has been added</p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                }

                {tileSelected &&
                    <div className={`family-tree-tile-view ${tileSelectedAnimation}`}>
                        <div className={`btn btn-modal-close`} onClick={handleCloseSelectedTile} style={{zIndex: 999}} >
                            <img src="/images/modal-close-x.svg"/>
                        </div>

                        <div className="selected-tile-wrapper">
                            <div className="background-text">{selectedTileName}</div>

                            <div className="selected-tile">
                                <div className="d-flex flex-column align-items-center gap-2">
                                    <img src="/images/headshot-placeholder.svg" />
                                    <p className="tile-name">{selectedTileName}</p>
                                </div>
                            </div>
                        </div>

                        <div className="selected-tile-link">
                            <p>Create {selectedTileName}'s memorial</p>
                            <img src="/images/fl-icon-create-memorial.svg" />
                        </div>
                        <div className="selected-tile-link">
                            <p>Link an existing memorial</p>
                            <img src="/images/fl-icon-connect-memorial.svg" />
                        </div>
                        <div className="selected-tile-link">
                            <p>Invite {selectedTileName}</p>
                            <img src="/images/fl-icon-send.svg" />
                        </div>
                        <div className="selected-tile-link">
                            <p>Add a relative to {selectedTileName}</p>
                            <img src="/images/fl-plus-lg.svg" width={24} height={24} />
                        </div>

                        <div className="selected-tile-bottom-pills">
                            <div className="selected-tile-pill edit">
                                <img src="/images/pill-icon-pen.svg" />
                                <p>Edit relationships</p>
                            </div>

                            <div className="selected-tile-pill remove">
                                <img src="/images/pill-icon-trash.svg" />
                                <p>Remove from tree</p>
                            </div>
                        </div>
                    </div>
                }

                <AddRelativePopup
                    handleSelectRelative={handleAddRelative}
                    posX={addRelativePosX}
                    posY={addRelativePosY}
                    relativeTo={addRelativePopupName}
                    relationFrom={addRelativeFrom}
                    handleOpen={addRelativePopup}
                    closeModal={() => setAddRelativePopup(false)}
                />

                <DetailedNode
                    handleOpen={detailedNodeActive}
                    closeModal={() => {setDetailedNodeActive(false); setRefreshCounter(c => c+1)}}
                    firstName={detailedNodeFirstName}
                    lastName={detailedNodeLastName}
                    relation={detailedNodeRelation}
                    id={detailedNodeId}
                    handleExtentionClick={handleAddRelativePopup}
                    posX={detailedNodePosX}
                    posY={detailedNodePosY}    
                    displayType={detailedNodeType}

                    openLinkMemorial={() => setLinkMemorialOpen(true)}
                />

                <DeceasedPlaceholderPopup relative={selectedRelative} showModal={deceasedRelativePopupOpen} />
                <LivingPlaceholderPopup relative={selectedRelative} showModal={livingRelativePopupOpen} />

                <LinkMemorial handleOpen={linkMemorialOpen} closeModal={() => setLinkMemorialOpen(false)} />

            </Modal>
        </>
    );
}