import { useTranslation } from "react-i18next";
import { GiftDonationType, LeafDonationType} from "../AppType";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { fetchRecords, debug } from "../../utils/AppUtil";
import { ModalDonateGift } from "../Modals/ModalDonateGift";


export const Tombstone: React.FC = () => {
    
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const leafClose = useRef<HTMLDivElement>(null);

    const [giftSelected, setGiftSeleted] = useState<boolean>(false);
    const [giftSelectedType, setGiftSeletedType] = useState<string>("");
    const [giftSelectedName, setGiftSeletedName] = useState<string>("");
    const [giftSelectedMessage, setGiftSeletedMessage] = useState<string>("");

    const [backgroundStyle, setBackgroundStyle] = useState<string>("tombstone-gift-display animate__animated animate__fadeIn");
    const [accordionStyle, setAccordionStyle] = useState<string>("accordion p-3 ms-3 animate__animated animate__fadeInUp");
    const [giftAnimation, setGiftAnimation] = useState<string>("animate__animated animate__fadeInUp");

    const [rotation, setRotation] = useState(0);
    const [giftPills, setGiftPills] = useState<React.ReactElement[]>();
    const [isLoading, setIsLoading] = useState(true);

    const [refreshCount, setRefreshCount] = useState<number>(0);

    const [touchStartY, setTouchStartY] = useState<number | null>(null);

    useEffect(() => {
        if(memorialData.memorialId){
            fetchRecords(commonData.token, `memorial/${memorialData.memorialId}/gift`, processGiftData)
            .catch((error) => {
                setIsLoading(false);
                debug('fetchRecords error: ' + error, 'Tombstone');
            });
        }
    }, [memorialData, refreshCount]);

    const processGiftData = (responseJson: GiftDonationType[]) => {
        setIsLoading(true);
        setGiftPills(buildGiftPillsJSX(responseJson));
        setIsLoading(false);
    }
    
    const buildGiftPillsJSX = (data: GiftDonationType[]): React.ReactElement[] => {
        
        const result: React.ReactElement[] = [];
        let key: number = 0;

        data.forEach((gift: GiftDonationType) => {

            const giftImageUrl: string = gift.giftType.name.toLowerCase();

            result.unshift(
                <div id={gift.giftId} key={key} className="leaf-pill" onClick={() => {handleGiftSelect(gift.giftId, gift.giftType.name, gift.user.firstname, gift.user.lastname, gift.message)}}>
                    <img src={`/images/tombstone-${giftImageUrl}.svg`} height={32} />
                    <div className="leaf-pill-text">
                        <p style={{whiteSpace: "nowrap"}}>{gift.giftType.name} from</p>
                        <p style={{whiteSpace: "nowrap"}}>{gift.user.firstname} {gift.user.lastname}</p>
                    </div>
                </div>
            );
            key++;

        });

        return result;
    }

    const pills = document.getElementsByClassName('leaf-pill');

    const handleGiftSelect = (id: string, type: string, firstName: string, lastName: string, message: string) => {        
        setGiftSeleted(true);
        setGiftSeletedType(type.toLowerCase());
        setGiftSeletedName(firstName + " " + lastName);
        setGiftSeletedMessage(message);
        
        setBackgroundStyle("tombstone-gift-display animate__animated animate__fadeIn");
        setAccordionStyle("accordion p-3 ms-3 animate__animated animate__fadeInUp")
        setGiftAnimation("animate__animated animate__fadeInUp")

        Array.prototype.forEach.call(pills, function(el) {
            el.classList.remove('leaf-selected');
        });

        document.getElementById(id)?.classList.add('leaf-selected');

        leafClose.current?.style.setProperty("pointer-events", "all");

        leafClose.current?.classList.remove('leaf-btn-slide-in');
        leafClose.current?.classList.remove("leaf-btn-slide-out");

        leafClose.current?.classList.add('leaf-btn-slide-in');
        leafClose.current?.style.setProperty('display', 'block');

    }

    const handleGiftClose = () => {
        setBackgroundStyle("tombstone-gift-display animate__animated animate__fadeOut");
        setAccordionStyle("accordion p-3 ms-3 animate__animated animate__fadeOutDown");
        setGiftAnimation("animate__animated animate__fadeOutDown");

        Array.prototype.forEach.call(pills, function(el) {
            el.classList.remove('leaf-selected');
        });
        
        leafClose.current?.classList.remove("leaf-btn-slide-in");
        leafClose.current?.classList.remove("leaf-btn-slide-out");
        
        leafClose.current?.classList.add("leaf-btn-slide-out");
        leafClose.current?.style.setProperty("pointer-events", "none");
    }

    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    // Swiping
    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setTouchStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (touchStartY !== null) {
          e.preventDefault(); // Prevent background scrolling during swipe
        }
    };

    const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        if (touchStartY === null) return;
        const endY = e.changedTouches[0].clientY;
        const deltaY = touchStartY - endY;
    
        // Swipe-up detected
        if (deltaY > 50 && !isExpanded) {
            setIsExpanded(true);
            setRotation(rotation - 180);
        }
  
        // Swipe-down detected
        if (deltaY < -50 && isExpanded) {
            setIsExpanded(false);
            setRotation(rotation + 180);
        }
    
        setTouchStartY(null); // Reset startY for the next swipe
      };

    const toggleAccordion = () => {
        if (isExpanded) {
            setRotation(rotation + 180);
        } else {
            setRotation(rotation - 180);
        }

        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = isExpanded ? `${contentRef.current.scrollHeight}px` : '64px';
        }
    }, [isExpanded]);

    // Increment the refresh count to trigger a refresh
    const handleRefresh = useCallback(() => {
        setRefreshCount(prevCount => prevCount + 1);
    }, []);

    return (
        <>
            <div className="p-3">

                <div className="timeline-tombstone-top card-container-top d-flex justify-content-center" style={{position: "relative", backgroundImage: "url('/images/tombstone-placeholder.png')"}}>
                    <div ref={leafClose} className="leaf-zoom-reset" onClick={handleGiftClose} style={{zIndex: "999"}}>
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76515 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12ZM8.031 17.031C7.89017 17.1718 7.69916 17.2509 7.5 17.2509C7.30084 17.2509 7.10983 17.1718 6.969 17.031C6.82817 16.8902 6.74905 16.6992 6.74905 16.5C6.74905 16.3008 6.82817 16.1098 6.969 15.969L10.9395 12L6.969 8.031C6.89927 7.96127 6.84395 7.87848 6.80621 7.78738C6.76848 7.69627 6.74905 7.59862 6.74905 7.5C6.74905 7.40138 6.76848 7.30373 6.80621 7.21262C6.84395 7.12152 6.89927 7.03873 6.969 6.969C7.10983 6.82817 7.30084 6.74905 7.5 6.74905C7.59862 6.74905 7.69627 6.76848 7.78738 6.80622C7.87848 6.84395 7.96127 6.89927 8.031 6.969L12 10.9395L15.969 6.969C16.0387 6.89927 16.1215 6.84395 16.2126 6.80622C16.3037 6.76848 16.4014 6.74905 16.5 6.74905C16.5986 6.74905 16.6963 6.76848 16.7874 6.80622C16.8785 6.84395 16.9613 6.89927 17.031 6.969C17.1007 7.03873 17.156 7.12152 17.1938 7.21262C17.2315 7.30373 17.2509 7.40138 17.2509 7.5C17.2509 7.59862 17.2315 7.69627 17.1938 7.78738C17.156 7.87848 17.1007 7.96127 17.031 8.031L13.0605 12L17.031 15.969C17.1007 16.0387 17.156 16.1215 17.1938 16.2126C17.2315 16.3037 17.2509 16.4014 17.2509 16.5C17.2509 16.5986 17.2315 16.6963 17.1938 16.7874C17.156 16.8785 17.1007 16.9613 17.031 17.031C16.9613 17.1007 16.8785 17.156 16.7874 17.1938C16.6963 17.2315 16.5986 17.2509 16.5 17.2509C16.4014 17.2509 16.3037 17.2315 16.2126 17.1938C16.1215 17.156 16.0387 17.1007 15.969 17.031L12 13.0605L8.031 17.031Z" fill="white" />
                        </svg>
                    </div>

                    <div className="tombstone-text">
                        <p className="tombstone-name">{memorialData.firstName}<br />{memorialData.lastName}</p>
                        <div className="tombstone-date d-flex flex-column gap-1">
                            <p>{memorialData.birthDateDisplay}</p>
                            <p>{memorialData.deathDateDisplay}</p>
                        </div>
                    </div>

                    {giftSelected &&
                        <div className={backgroundStyle}>
                            <img src={`/images/tombstone-${giftSelectedType}.svg`} className={giftAnimation} height={154} />

                            <div className={accordionStyle} style={{minHeight: "96px"}}>
                                <div ref={contentRef}
                                    onClick={toggleAccordion}
                                    onTouchStart={handleTouchStart}
                                    onTouchMove={handleTouchMove}
                                    onTouchEnd={handleTouchEnd}
                                    className="accordion-content"
                                >
                                    <div className="accordion-toggle">
                                        <img src="/images/fl-chevron-up.svg" className="rotate-image" style={{ transform: `rotate(${rotation}deg)` }} />
                                    </div>
                                    <div className="gift-text d-flex flex-column gap-2">
                                        <p>{giftSelectedName}</p>
                                        <p>{giftSelectedMessage}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                </div>

                <div className="card-container-bottom-tombstone" style={{zIndex: 999, position: "relative"}}>
                    <div className="leaf-pills gap-3 fade-container">
                        {giftPills}
                    </div>

                    <div className="w-100 p-3">
                        <ModalDonateGift onGiftCreated={handleRefresh} />
                    </div>
                </div>
                
            </div>
        </>
    );
}