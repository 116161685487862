import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { LeafDonateType, LeafDonationType, MemorialType, UpdateFamilyStoryType, LeafType, CurrencyType, PaymentProviderType } from "../AppType";
import { Modal } from "react-bootstrap";
import { ASSET_URL, debug, fetchRecords, postFormData } from "../../utils/AppUtil";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import { ModalSetLeafAmount } from "./ModalSetLeafAmount";
import 'animate.css';

type ModalProps = {
    onLeafCreated: () => void;
}

export const ModalDonateLeaf: React.FC<ModalProps> = ({onLeafCreated}) => {
    
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Refs
    const refs = useRef<(HTMLDivElement | null)[]>([]);

    // States
    const [formStep, setFormStep] = useState<number>(0);
    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalFail, setModalFail] = useState<string>("");
    const [isTransitioning, setIsTransitioning] = useState<Boolean>(false);

    const [loggedInUser, setLoggedInUser] = useState<Boolean>(true);
    const [paymentProviderId, setPaymentProviderId] = useState<String>('');
    
    const [leafTiles, setLeafTiles] = useState<React.ReactElement[]>();
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const [currency, setCurrency] = useState<string>('USD');
    const [currencySymbol, setCurrencySymbol] = useState<string | undefined>('R');
    const [tipAmount, setTipAmount] = useState<number>(10);
    const [donateAmount, setDonateAmount] = useState<number | null>(0);
    const [totalAmount, setTotalAmount] = useState<number | null>(0);
    const [scarletActive, setScarletActive] = useState<boolean>(false);

    // Initial formData values
    const formData: LeafDonateType = {
        leafTypeId: "",
        paymentProviderId: "",
        currencyId: "",
        amount: 0,
        anonymous: false
    }

    // Set formState to formData
    const [formState, setFormState] = useState<LeafDonateType>(formData);

    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (show: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        // Define a ref to store the starting X position of the touch
        const startX = useRef<number | null>(null);
    
        useEffect(() => {
            const handleTouchStart = (e: TouchEvent) => {
                if (modalRef.current) {
                    startX.current = e.touches[0].clientX;
                }
            };
    
            const handleTouchMove = (e: TouchEvent) => {
                if (modalRef.current && startX.current !== null) {
                    const modal = modalRef.current;
                    const touchX = e.touches[0].clientX;
                    const deltaX = startX.current - touchX;
    
                    const canScrollLeft = modal.scrollLeft > 0;
                    const canScrollRight = modal.scrollWidth > modal.clientWidth + modal.scrollLeft;
    
                    if (modal.contains(e.target as Node)) {
                        // Scrolling inside the modalRef div
                        if ((deltaX > 0 && !canScrollRight) || (deltaX < 0 && !canScrollLeft)) {
                            e.preventDefault(); // Prevent scrolling outside the modalRef div
                        }
                    } else {
                        // Scrolling outside the modalRef div
                        e.preventDefault(); // Prevent scrolling the entire modal
                    }
    
                    startX.current = touchX; // Update startX for continuous movement
                }
            };
    
            if (show) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchstart', handleTouchStart, { passive: true });
                window.addEventListener('touchmove', handleTouchMove, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchmove', handleTouchMove);
            }
    
            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchmove', handleTouchMove);
            };
        }, [show, modalRef]); // Dependencies for the useEffect hook
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(show, modalRef);

    // Fetch currencies from API
    useEffect(() => {
        fetchRecords(commonData.token, 'currency', processCurrencyData).catch((error) => {
            debug('fetchRecords error: ' + error, 'currency');
        });

        fetchRecords(commonData.token, 'payment_providers', processProviderData).catch((error) => {
            debug('fetchRecords error: ' + error, 'payment_providers');
        });
    }, [activeIndex]);

    // Process currency data
    const processCurrencyData = (responseJson: CurrencyType) => {
        const getCurrencyId = responseJson.find(id => id.name === currency);
        const setCurrencyId = getCurrencyId?.currencyId;
        const symbol = getCurrencyId?.symbol;
        setCurrencySymbol(symbol);

        setFormState((formState) => ({
            ...formState,
            currencyId: setCurrencyId
        }));

        console.log('CURRENCY Response:', responseJson);
        console.log('CURRENCY Get:', getCurrencyId);
        console.log('CURRENCY Set:', setCurrencyId);
    }

    const processProviderData = (responseJSon: PaymentProviderType) => {
        setFormState((formState) => ({
            ...formState,
            paymentProviderId: responseJSon[0].paymentProviderId
        }));
    }

    // Fetch leaves data from API
    useEffect(() => {
        fetchRecords(commonData.token, `leaf_type`, processLeafData);
    }, [activeIndex]);
    
    // Process leaves data
    const processLeafData = (responseJson: LeafType[]) => {
        setLeafTiles(buildLeafTiles(responseJson));
    }

    // Convert days into weeks (currently not used - durations removed)
    const convertDaysToWeeks = (numDays: number): string => {
        const weeks = Math.floor(numDays / 7);
        const remainingDays = numDays % 7;
        
        if (numDays === null) {
            return 'Lasts forever'
        }

        if (weeks > 0 && remainingDays === 0) {
            return `Lasts ${weeks} week${weeks > 1 ? 's' : ''}`;
        } else if (weeks > 0 && remainingDays > 0) {
            return `Lasts ${weeks} week${weeks > 1 ? 's' : ''} ${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
        } else {
            return `Lasts ${numDays} day${numDays > 1 ? 's' : ''}`;
        }
    }
    
    // Build leaf tiles JSX
    const buildLeafTiles = (data: LeafType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
    
        data.forEach((leaf: LeafType, index: number) => {   
            const leafCurrency: number | any = leaf.prices.find(price => price.currency.name === currency);
            const imageName: string = leaf.name.toLowerCase().replace(/ /g, "-");

            result.push(
                <div
                    key={index}
                    ref={el => (refs.current[index] = el)}
                    onClick={() => handleClick(index, leaf.leafTypeId, leafCurrency?.price)} // Use the function that captures the correct index
                    className={`donate-leaf-tile ${activeIndex === index ? 'leaf-active' : ''}`}
                    style={{
                        background: `linear-gradient(180deg, rgba(26, 50, 60, 0) 0%, #1A323C 100%), url('/images/buy-leaf-${imageName}.svg')`,
                        backgroundPosition: activeIndex === index ? 'center top' : 'center'
                    }}
                >
                    <div className={`leaf-tile-select ${activeIndex === index ? 'selected' : ''}`}>
                        <img src="/images/check-tick.svg" alt="" />
                    </div>
    
                    <div className={`leaf-tile-text ${activeIndex === index ? 'selected' : ''}`}>
                        <p className="leaf-name">{leaf.name}</p>
                        {leaf.name === 'Scarlet Leaf' ?
                            <p className="leaf-cost-custom">Custom amount</p>
                            :
                            <p className="leaf-cost">{leafCurrency?.currency.symbol}{leafCurrency?.price}</p>
                        }
                    </div>
                </div>
            );
        });
    
        return result;
    };

    const backgroundImg: string = `${ASSET_URL}/public/picture/${memorialData.coverPictureId}`
    let backgroundStyle;
    if (backgroundImg !== null) {
        backgroundStyle = {backgroundImage: `url(${backgroundImg})`};
    }

    // const handleClick = (index: number) => {
    //     console.log('Clicked index:', index);
    //     console.log('Previous activeIndex:', activeIndex);

    //     setActiveIndex(prevIndex => {
    //         const newIndex = (prevIndex === index ? null : index);
    //         console.log('New activeIndex:', newIndex);
    //         return newIndex;
    //     });

    //     setDonateAmount(leafAdditionals.amounts[index]);
    //     console.log('New donateAmount:', leafAdditionals.amounts[index]);

    //     if (index === 3) {
    //         setScarletActive(true);
    //     } else {
    //         setScarletActive(false);
    //     }
    // }

    const handleClick = (index: number, id: string, cost: number) => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));

        setFormState((formState) => ({
            ...formState,
            leafTypeId: id,
            amount: cost
        }));

        setDonateAmount(cost);
    
        if (index === 3) {
            setScarletActive(true);
        } else {
            setScarletActive(false);
        }
    }

    const handleCustomAmount = (newNumber: number) => {
        console.log(newNumber);
        setDonateAmount(newNumber);
        setFormStep(formStep + 1);
    }

    const handleShow = () => {
        setModalSuccess(false);
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideOutDown');
        setShow(false);
    }

    const handleReset = () => {
        setModalFail("");
    }

    // - - - - - Change Form Step - - - - -
    const handleClickNext = () => {
        if (formStep === 1) {
            if (donateAmount) {
                setTotalAmount(donateAmount + tipAmount);
            }
        }
        if (activeIndex !== null) {
            setFormStep(formStep + 1);
        }
    }

    const handleClickBack = () => {
        setFormStep(formStep - 1);

        setIsTransitioning(true);
        setTimeout(() => {
            setIsTransitioning(false);
        }, 500);
    }

    const handleTipInc = () => {
        if (tipAmount < 50) {
            setTipAmount(c => (c + 5));
        }
    }

    const handleTipDec = () => {
        if (tipAmount > 0) {
            setTipAmount(c => (c - 5));
        }
    }

    // Post data
    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        console.log(formState);

        // postFormData(
        //     commonData.token,
        //     `memorial/${memorialData.memorialId}/leaf`,
        //     formState,
        //     (response: any) => {
        //         setModalSuccess(true);
        //         console.log("handlePost Success Response:", JSON.stringify(response));
        //     },
        //     (error: any) => {
        //         setModalSuccess(false);
        //         setModalFail(JSON.stringify(error));
        //         console.log("handlePost FAILED Response:", JSON.stringify(error));
        //     }
        // );

        // handleClickNext();
        // onLeafCreated();

        // setTimeout(() => {
        //     handleClose();
        // }, 2000);
    }

    return (
        <>
            <button className="btn-light" onClick={handleShow} style={{border: "none"}}>
                <div className="btn-txt">{ t("TreeOfLife.donateALeaf", {ns: "components"}) }</div>
            </button>

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    {formStep === 0 ?
                        <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClose}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                        :
                        <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClickBack}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                    }
                    <p className="modal-title">Donate a leaf</p>
                    <div className="btn btn-modal-close" onClick={handleClose} style={{zIndex: 999}}><img src="/images/modal-close-x.svg"/></div>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-progress form-progress-absolute">
                        <img src={`/images/fl-progress-leaf.svg`} className={`form-progress-current form-progress-${formStep}`} alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />

                        {!loggedInUser &&
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        }
                    </div>

                    <div className="form-container">
                        <div className={`form-screen form-content px-3 form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Donate a Leaf</p>
                                    <p>By donating a leaf, you can help support one of {memorialData.firstName}’s connected charities.</p>
                                </div>

                                <div className="donate-leaf-tiles px-3" ref={modalRef}>
                                    {leafTiles}
                                </div>

                            </div>
                        </div>

                        <div className={`form-screen form-content px-3 form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                <p>Please help us</p>
                                <p></p>
                            </div>

                            <div className="leaf-tip-wrapper">
                                <img src="/images/fl-logo-small.svg" width={40} height={40} alt="" />
                                <div className="leaf-tip-top">
                                    <p>Share the love</p>
                                    <p>Forever Loved doesn’t charge any fees on payments. Adding a small tip helps us to continue to help more people and charities.</p>
                                </div>

                                <hr />
                                
                                <div className="leaf-tip-bottom">
                                    <p>Tip to Forever Loved</p>
                                    <div className="leaf-tip-amount">
                                        <img src="/images/icon-circle-minus.svg" onClick={handleTipDec} width={24} height={24} alt="" />
                                        <p>{currencySymbol}{tipAmount}.00</p>
                                        <img src="/images/icon-circle-plus.svg" onClick={handleTipInc} width={24} height={24} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className={`form-screen form-content px-3 form-content-${formStep === 2 ? 'current' : (formStep > 2 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2">
                            
                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Select your charity</p>
                                    <p>The following charities are connected to communities that Noam is part of.</p>
                                </div>

                                <div className="leaf-charities-wrapper px-3">
                                    <div className="leaf-charity-tile">
                                        <img src="/images/charity-placeholder-cancer.png" />
                                        <p>Cancer Research</p>
                                    </div>

                                    <div className="leaf-charity-tile">
                                        <img src="/images/charity-placeholder-agency.png" />
                                        <p>The Jewish Agency for Israel</p>
                                    </div>

                                    <div className="leaf-charity-tile">
                                        <img src="/images/charity-placeholder-cancer.png" />
                                        <p>Cancer Research</p>
                                    </div>
                                </div>

                            </div>
                        </div> */}

                        {!loggedInUser &&
                            <div className={`form-screen form-content px-3 form-content-${formStep === 2 ? 'current' : (formStep > 2 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                                <div className="d-flex flex-column gap-2 mb-4">
                                    <p className="modal-heading">Let them know who this leaf is from</p>
                                    <p></p>
                                </div>
                                
                                <div className="login-form">
                                    <div className="mb-4">
                                        <label htmlFor="firstName" className="form-label">First Name</label>
                                        <input id="firstName" name="firstName" type="text" className="form-control" placeholder="Enter your own first name" />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <input id="lastName" name="lastName" type="text" className="form-control" placeholder="Enter your own last name" />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input id="email" name="email" type="email" className="form-control" placeholder="name@domain.com" />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={`form-screen form-content px-3 form-content-${formStep === (loggedInUser ? 2 : 3) ? 'current' : (formStep > (loggedInUser ? 2 : 3) ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="modal-body-heading d-flex flex-column gap-1 mb-4">
                                <p className="mb-3">Your donation</p>
                                <p>Total payment</p>
                                <span className="donate-total">{currencySymbol}{totalAmount}.00</span>
                            </div>

                            <div className="reoccuring-donation-switch form-check form-switch">
                                <input className="form-check-input m-0" type="checkbox" role="switch" id="toggleLanguageSwitch" placeholder="" title=""/>
                                <label className="form-check-label" htmlFor="toggleLanguageSwitch">Make my donation reoccurring (monthly).</label>
                            </div>

                            <div className="d-flex align-items-center gap-3 mt-2">
                                <img src="/images/fl-heart.svg" width={16} height={16} />
                                <p className="input-subtext" style={{color: "#FFFFFF"}}>Keep making a difference by setting up recurring donations to your chosen charity.</p>
                            </div>

                            <hr className="form-hr mx-auto" />

                            <div className="d-flex gap-2">
                                <div className="payment-gateway-btn">Card</div>
                                <div className="payment-gateway-btn">Apple Pay</div>
                                <div className="payment-gateway-btn">PayPal</div>
                            </div>

                        </div>

                        {/* Step 3 - success / fail */}
                        <div className={`form-screen form-content px-3 form-content-${formStep === (loggedInUser ? 3 : 4) ? 'current' : (formStep > (loggedInUser ? 3 : 4) ? 'prev' : 'next')}`}>
                            <div className="d-flex flex-column justify-content-center h-100">
                                
                                <div className="d-flex flex-column gap-3 align-items-center justify-content-center mb-5">
                                    {modalSuccess ?
                                        <>
                                            <img src="/images/fl-login-tick.svg" />
                                            <div className="login-success-txt d-flex flex-column gap-1">
                                                <p>Leaf created</p>
                                                <p>Thank you for your kindness</p>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <img src="/images/fl-icon-error.svg" />
                                            <div className="login-success-txt d-flex flex-column gap-1">
                                                <p>Something went wrong</p>
                                                <p>Please try again</p>
                                            </div>
                                        </>
                                    }
                                    
                                </div>

                            </div>
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {(formStep === 0 || formStep === 1) &&
                        <>
                            {(formStep === 0 && scarletActive) ?
                                <ModalSetLeafAmount setCustomAmount={handleCustomAmount} />
                                :
                                <button className="btn btn-modal-footer" onClick={handleClickNext}>Continue</button>
                            }
                        </>
                    }

                    {!loggedInUser && formStep === 2 &&
                        <button className="btn btn-modal-footer" onClick={handleClickNext}>Continue</button>
                    }

                    {formStep === (loggedInUser ? 2 : 3) &&
                        <>
                            <button className="btn btn-modal-footer" onClick={handlePost}>Pay Now</button>
                            <div className="modal-footer-links d-flex justify-content-center gap-3 pt-4 pb-2 m-0 w-100">
                                <p>Privacy Policy</p>
                                <div className="modal-link-separator" />
                                <p>Terms & Conditions</p>
                            </div>
                        </>
                    }

                    {formStep === (loggedInUser ? 3 : 4) &&
                        <button className="btn btn-modal-footer" onClick={handleClose}>Continue</button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}





