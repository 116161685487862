import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css"
import { deleteRecord, postFormData } from "../../utils/AppUtil";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";

type ModalProps = {
    firstName: string;
    lastName: string;
    relation: string;
    posX: number;
    posY: number,
    id: string;
    displayType: string;
    handleOpen: boolean;
    closeModal: () => void;
    openLinkMemorial: () => void;
    handleExtentionClick: (
        firstName: string,
        posX: number,
        posY: number,
        relationFrom: string,
        treeNodeId: string
    ) => void
}

export const DetailedNode: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Modal states
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.handleOpen);
    }, [props.handleOpen]);

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        props.closeModal();
    }

    const handleAddRelative = () => {
        props.handleExtentionClick( props.firstName, props.posX, props.posY, props.relation, props.id);
        handleClose();
    }

    const handleOpenLinkMemorial = () => {
        handleClose();
        props.openLinkMemorial();
    }

    const handleDelete = () => {
        deleteRecord(
            commonData.token,
            `user/loggedIn/familyTree/${props.id}`,
            (response: any) => {
                console.log("Node deleted");
            },
            (error: any) => { // error

            }
        );

        handleClose();
    }

    // Select relative
    // const handleSelectRelative = (relative: string, addPosY: number, handleFrom: string, handleTo: string) => {
    //     props.handleSelectRelative(relative, addPosY, handleFrom, handleTo);
    //     handleClose();
    // }

    return (
        <>
            <Modal show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Body className="p-0">
                    <div className={`family-tree-tile-view `}>
                        <div className={`btn btn-modal-close`} onClick={handleClose} style={{zIndex: 9999}}>
                            <img src="/images/modal-close-x.svg"/>
                        </div>

                        <div className="selected-tile-wrapper">
                            <div className="background-text">{props.lastName}</div>

                            <div className="selected-tile">
                                <div className="d-flex flex-column align-items-center gap-2">
                                    <img src="/images/headshot-placeholder.svg" />

                                    <div className="d-flex flex-column">
                                        <p className="tile-name">{props.firstName}</p>
                                        <p className="tile-name">{props.lastName}</p>
                                    </div>
                                </div>

                                <p className="tile-relation">{props.relation}</p>
                            </div>
                        </div>

                        {props.displayType === "proxy" &&
                            <>
                                <div className="selected-tile-link">
                                    <p>Create {props.firstName}'s memorial</p>
                                    <img src="/images/fl-icon-create-memorial.svg" />
                                </div>
                                <div className="selected-tile-link" onClick={handleOpenLinkMemorial}>
                                    <p>Link an existing memorial</p>
                                    <img src="/images/fl-icon-connect-memorial.svg" />
                                </div>
                                <div className="selected-tile-link">
                                    <p>Invite {props.firstName}</p>
                                    <img src="/images/fl-icon-send.svg" />
                                </div>
                            </>
                        }

                        <div className="selected-tile-link"
                            onClick={handleAddRelative}
                        >
                            <p>Add a relative to {props.firstName}</p>
                            <img src="/images/fl-plus-lg.svg" width={24} height={24} />
                        </div>

                        <div className="selected-tile-bottom-pills">
                            <div className="selected-tile-pill edit">
                                <img src="/images/pill-icon-pen.svg" />
                                <p>Edit relationships</p>
                            </div>

                            <div className="selected-tile-pill remove" onClick={() => handleDelete()}>
                                <img src="/images/pill-icon-trash.svg" />
                                <p>Remove from tree</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}