import React, { MutableRefObject, RefObject, useContext, useEffect, useRef, useState } from "react";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";
import { ASSET_URL } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";

type LifeEventProps = {
    title: string,
    message: string,
    eventDateDisplay: string,
    images: string[] | undefined,
    locations: string[] | undefined,

    showModal: boolean;
    onHide: () => void;
}

export const LifeEventDetailed: React.FC<LifeEventProps> = ({onHide, showModal, ...props}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (showModal: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        useEffect(() => {
            const preventScroll = (e: TouchEvent) => {
                if (modalRef.current && modalRef.current.contains(e.target as Node)) {
                    return; // Allow scrolling inside the modal
                }

                e.preventDefault(); // Prevent scrolling outside the modal (i.e., body scroll)
            };

            if (showModal) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchmove', preventScroll, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchmove', preventScroll);
            }

            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            };
        }, [showModal, modalRef]);
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(showModal, modalRef);

        
    return (
        <Modal className="modal-life-event" show={showModal} fullscreen={true} onHide={onHide} backdrop={false}>
            <Modal.Body ref={modalRef}>
                <div className="d-flex justify-content-between mb-5">
                    <div className="btn-modal-wrapper" onClick={onHide}>
                        <img src="/images/modal-back-chev.svg" />
                    </div>

                    <div className="d-flex gap-3">
                        <div className="btn-modal-wrapper">
                            <img src="/images/fl-icon-pen.svg"/>
                        </div>
                        
                        <div className="btn-modal-wrapper">
                            <img src="/images/fl-share-thick.svg"/>
                        </div>
                    </div>
                </div>
            
                <div className="d-flex flex-column gap-2">

                    <div className="d-flex gap-2 mb-4">
                        {props.locations?.map((location, index) => {
                            return (
                                <div key={index} className="timeline-location-pill d-flex gap-2 px-2 py-1">
                                    <img src="/images/fl-map-pin.svg" />
                                    <p>{location}</p>
                                </div>
                            )
                        })}
                    </div>
                    
                    <p className="fl-txt-title">{props.title}</p>
                    <p className="fl-life-event-date">{props.eventDateDisplay}</p>
                    <p className="fl-txt-subtitle">{props.message}</p>
                </div>

                <div className="life-event-detailed-image-wrapper d-flex flex-column align-items-center gap-5 mt-5">

                    {props.images?.map((image, index) => {
                        return (
                            <img key={index} src={`${ASSET_URL}/public/picture/${image}`} className="life-event-detailed-image"  />
                        )
                    })}
                </div>
            </Modal.Body>
        </Modal>
    );
}