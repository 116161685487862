import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { MemorialContext } from "../../MemorialContext";
import { AppContext } from "../../AppContext";

export const SetupWidget: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [expanded, setExpanded] = useState<string>('none');
    const [expandedHeight, setExpandedHeight] = useState<string>('0');
    const [buttonSVG, setButtonSVG] = useState<string>('/images/fl-arrow-down.svg')

    const handleClick = () => {
        if (expanded === 'none') {
            setExpanded('block');
            setExpandedHeight('24px');
            setButtonSVG('/images/fl-arrow-up.svg');
        } else {
            setExpanded('none');
            setButtonSVG('/images/fl-arrow-down.svg');
            setExpandedHeight('0');
        }
    }

    return (
        <>
            <div className="memorial-setup-widget d-flex flex-column mx-3 px-3 py-4 my-3">
                <div className="memorial-setup-txt d-flex flex-column gap-3 mb-3">
                    <div className="d-flex justify-content-between">
                        <p>{ t("SetupWidget.title", {name: `${memorialData.firstName}`, ns: "components"}) }</p>
                        <img onClick={handleClick} src={buttonSVG} className="setup-close" style={{cursor: 'pointer'}} />
                    </div>
                    <p className="widget-expand" style={{height: expandedHeight, color: "white"}}><span>{ t("SetupWidget.step01Symbol", {ns: "components"}) }</span>{ t("SetupWidget.step01Description", {ns: "components"}) }</p>
                </div>
                <div className="memorial-setup-progress-wrapper mb-2">
                    <div className="memorial-setup-progress" />
                </div>
                <div className="btn-setup mt-3 widget-expand" style={{height: expandedHeight}}>{ t("SetupWidget.continue", {ns: "components"}) }</div>
            </div>

        </>
    );
}