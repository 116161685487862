import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

declare global {
    interface BeforeInstallPromptEvent extends Event {
        readonly platforms: string[];
        readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed'; platform: string; }>;
        prompt: () => Promise<void>;
    }

    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

export const AddToHome: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Modal states
    const [modalAnimation, setModalAnimation] = useState<string>('');
    const [show, setShow] = useState(false);

    // Install prompt states
    const [installPromptEvent, setInstallPromptEvent] = useState<BeforeInstallPromptEvent | null>(null);
    const [isAppInstalled, setIsAppInstalled] = useState<boolean>(false);
    const [isIos, setIsIos] = useState<boolean>(false);

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (show) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
      }, [show]);

    useEffect(() => {

        // Check for iOS
        const checkIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        }

        // Set iOS or not
        if (checkIos()) {
            setIsIos(true);
        } else {
            setIsIos(false);
        }

        // Listen for the beforeinstallprompt event
        const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
            event.preventDefault(); // Prevent the default mini-infobar from appearing on mobile
            setInstallPromptEvent(event); // Stash the event so it can be triggered later
        }

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        // Check if the app is installed
        const handleAppInstalled = () => setIsAppInstalled(true);

        const checkInstalledStatus = () => {
            const mediaQuery = window.matchMedia('(display-mode: standalone)');
            const iosInstalled = (window.navigator as any).standalone;
            setIsAppInstalled(mediaQuery.matches || iosInstalled);
        }

        checkInstalledStatus();
        window.addEventListener('appinstalled', handleAppInstalled);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    useEffect(() => {
        if (!isAppInstalled && (installPromptEvent || isIos)) {
            handleShow();
        }
    }, [isAppInstalled, installPromptEvent, isIos]);

    // const handleInstallClick = () => {
    //     if (installPromptEvent) {
    //         installPromptEvent.prompt();
    //         installPromptEvent.userChoice.then((choiceResult) => {
    //             if (choiceResult.outcome === 'accepted') {
    //                 console.log('User accepted the install prompt');
    //             } else {
    //                 console.log('User dismissed the install prompt');
    //             }
    //             setInstallPromptEvent(null);
    //         });
    //     }
    // }

    if (isAppInstalled) {
        return null; // Don't show the prompt if the app is installed
    }

    const handleShow = () => {
        setModalAnimation(`modal-add-to-home${isIos ? '-ios' : ''} animate__animated animate__slideIn${isIos ? 'Up' : 'Down'}`);
        setShow(true);
    }

    const handleClose = () => {
        setModalAnimation(`modal-add-to-home${isIos ? '-ios' : ''} animate__animated animate__slideOut${isIos ? 'Down' : 'Up'}`);
        setTimeout(() => {
            setShow(false);
        }, 300);
    }

    return (
        <>
            {/* <img src="/images/fl-bell.svg" className="home-icon-top p-2" height={40} onClick={handleShow} style={{cursor: "pointer", display: "none"}} /> */}

            <Modal className={modalAnimation} show={show} onHide={handleClose}>

                {!isIos && <img src="/images/popup-caret.svg" style={{ width: "34px", margin: "0 16px -1px auto" }} />}

                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <p className="add-to-home-heading">Add to Home Screen</p>
                        <p className="add-to-home-copy" style={{ color: "#0A84FF", cursor: "pointer" }} onClick={handleClose}>Cancel</p>
                    </div>

                    <hr style={{ borderTopColor: "#00000080" }} />
                    <p className="add-to-home-copy">This website has app functionality. Add it to your home screen to improve your experience.</p>
                    <hr style={{ borderTopColor: "#00000080" }} />

                    <div className="d-flex align-items-center gap-2 mb-2">
                        <img src="images/add-step-1.svg" />

                        {isIos ?
                            <img src="images/add-step-1-icon-ios.svg" />:
                            <img src="images/add-step-1-icon.svg" />
                        }

                        {isIos ?
                            <p className="add-to-home-copy">Press <b>‘Share’</b> on the menu bar below</p>:
                            <p className="add-to-home-copy">Press the <b>three dots</b> in the menu bar above</p>
                        }
                            
                    </div>

                    <div className="d-flex align-items-center gap-2">
                        <img src="images/add-step-2.svg" />

                        {isIos ?
                            <img src="images/add-step-2-icon-ios.svg" />:
                            <img src="images/add-step-2-icon.svg" />
                        }
                        
                        <p className="add-to-home-copy">Press <b>‘Add to Home Screen’</b></p>
                    </div>
                </Modal.Body>

                {isIos && <img src="/images/popup-caret.svg" style={{ width: "34px", margin: "-1px auto 0", transform: "rotateX(180deg)" }} />}

            </Modal>
        </>
    );
}

export default AddToHome;
