import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import { ModalContacts } from "../Modals/ModalContacts";
import { ModalUserPicture } from "./ModalUserPicture";
import { ASSET_URL, debug, fetchRecords } from "../../utils/AppUtil";
import { GetUserType } from "../AppType";
import { MyNetwork } from "./MyNetwork";
import { ModalVerifyAccount } from "../Modals/ModalVerifyAccount";

export const UserAccount: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [show, setShow] = useState(false);
    const [pictureSrc, setPictureSrc] = useState<string>('/images/account-avatar.svg');

    const [formStep, setFormStep] = useState<number>(0);
    const [selectedTile, setSelectedTile] = useState<string>('');

    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (showModal: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        useEffect(() => {
            const preventScroll = (e: TouchEvent) => {
                if (modalRef.current && modalRef.current.contains(e.target as Node)) {
                    return; // Allow scrolling inside the modal
                }

                e.preventDefault(); // Prevent scrolling outside the modal (i.e., body scroll)
            };

            if (showModal) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchmove', preventScroll, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchmove', preventScroll);
            }

            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            };
        }, [showModal, modalRef]);
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(show, modalRef);

    useEffect(() => {
        fetchRecords(commonData.token, `user/loggedIn`, processUserData)
        .catch((error) => {
            debug('fetchRecords error: ' + error, 'user');
        });
    }, [show])

    const processUserData = (responseJson: GetUserType) => {
        if (responseJson.profilePicture.pictureId) {
            setPictureSrc(`${ASSET_URL}/public/picture/${responseJson.profilePicture.pictureId}`);
        }
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
    }

    return (
        <>
            <img src="/images/fl-user.svg" className="home-icon-top" height={40} onClick={handleShow} />

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header className="align-start">
                    <p className="modal-title">My account</p>
                    <div className="btn btn-modal-close" onClick={handleClose} style={{zIndex: 999}}><img src="/images/modal-close-x.svg"/></div>
                </Modal.Header>

                <Modal.Body ref={modalRef}>
                    <div className={`form-screen scroll-active form-content p-3 form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')}`}>
                        
                        <div className="d-flex align-items-center pb-4 gap-4">                                
                            <div className="account-avatar-wrapper">
                                <img className="profile-img" src={pictureSrc} width={104} height={104} />
                                <ModalUserPicture />
                            </div>
                            
                            <div className="user-account-details d-flex flex-column gap-1">
                                <p>{commonData.firstName}<br/>{commonData.lastName}</p>
                                <p>Joined in 2020</p>
                            </div>
                        </div>
                        
                        <div className="card card-find-connections mb-3">
                            <img src="/images/img-find-connections.png" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <div className="card-text d-flex flex-column gap-2 mb-4">
                                    <p>Be the friend who cares</p>
                                    <p>Grow your Forever Loved network to share memories and be reminded on special days.</p>
                                </div>
                                <button className="fl-btn-white-large">Find your contacts</button>
                            </div>
                        </div>

                        <div className="d-flex flex-column gap-3">
                            <div className="d-flex gap-3">
                                
                                <MyNetwork />
                                <div className="my-account-tile">
                                    <img src="images/img-my-family-tree.svg" />
                                    <p>My family tree</p>
                                </div>
                            </div>

                            <div className="d-flex gap-3">
                                <div className="my-account-tile">
                                    <img src="images/img-settings.svg" />
                                    <p>Settings</p>
                                </div>

                                <div className="my-account-tile">
                                    <img src="images/img-payments.svg" />
                                    <p>Payments</p>
                                </div>
                            </div>

                            <div className="d-flex gap-3">
                                <div className="my-account-tile">
                                    <img src="images/img-get-the-app.svg" />
                                    <p>Get the app</p>
                                </div>

                                <ModalVerifyAccount />
                            </div>
                        </div>
                    </div>
                    
                    <div className={`form-screen scroll-active form-content form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')}`}>
                        <p style={{color: 'white'}}>{selectedTile}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}