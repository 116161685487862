import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { Modal } from "react-bootstrap";
import { useSpring} from '@react-spring/web';
import { useTranslation } from "react-i18next";
import { debug, fetchRecords, postFormData } from "../../utils/AppUtil";
import { MemorialUserType, MemorialUserRoleType } from "../AppType";
import 'animate.css';

type ModalProps = {
    endpoint: string
    modalClose: () => void;
}

export const ModalManageRoles: React.FC<ModalProps> = ({modalClose, ...props}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Modal handlers
    const [show, setShow] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);

    const [memorialUsers, setMemorialUsers] = useState<React.ReactElement[]>();
    const [selectedUserFirstName, setSelectedUserFirstName] = useState<string>("");
    const [selectedUserLastName, setSelectedUserLastName] = useState<string>("");

    const [removeUser, setRemoveUser] = useState<boolean>(false);

    const [formStep, setFormStep] = useState<number>(0);
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [formSuccessAnimation, setFormSuccessAnimation] = useState<string>("slideInRight");

    const formData: MemorialUserType = {
        userId: "",
        role: ""
    }

    // Set form state
    const [formState, setFormState] = useState<MemorialUserType>(formData);


    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (showModal: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        useEffect(() => {
            const preventScroll = (e: TouchEvent) => {
                if (modalRef.current && modalRef.current.contains(e.target as Node)) {
                    return; // Allow scrolling inside the modal
                }

                e.preventDefault(); // Prevent scrolling outside the modal (i.e., body scroll)
            };

            if (showModal) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchmove', preventScroll, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchmove', preventScroll);
            }

            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            };
        }, [showModal, modalRef]);
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(show, modalRef);

    useEffect(() => {

        fetchRecords(commonData.token, `memorial/${memorialData.memorialId}/users`, processUsersData)
        .catch((error) => {
            debug('fetchRecords error: ' + error, 'Home');
        });
        
    }, [formSuccess]);

    const processUsersData = (responseJson: MemorialUserRoleType[]) => {
        setMemorialUsers(buildUsersJsx(responseJson));
    }

    const adminColor = {color: '#B9EBFF'};
    const contributorColor = {color: '#FFE69C'};
    const viewerColor = {color: '#F1AEB5'};

    const buildUsersJsx = (response: MemorialUserRoleType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        let roleColor = viewerColor;

        response.forEach((user: MemorialUserRoleType) => {

            if (user.role === 'CREATOR' || user.role === 'ADMIN') {
                roleColor = adminColor;
            } else if (user.role === 'CONTRIBUTOR') {
                roleColor = contributorColor;
            } else {
                roleColor = viewerColor;
            }

            result.push(
                <div key={key} className="manage-roles-list-item py-3">
                    <img className="list-item-avatar" src="images/fl-days-avatar.svg" />
                    <div className="d-flex flex-column gap-2">
                        <p className="list-item-name">{user.user.firstname} {user.user.lastname}</p>
                        <div className="list-item-badge" style={roleColor}>
                            {toCamelCase(user.role)} {user.user.userId === commonData.userId && '(You)'}
                        </div>
                    </div>

                    {user.role !== 'CREATOR' &&
                        <img className="list-item-edit" src="images/fl-icon-pen.svg" width={24} height={24} onClick={() => handleManageRole(user.user.userId, user.user.firstname, user.user.lastname, user.role)} />
                    }
                </div>
            );
        });
        
        return result;
    }

    const toCamelCase = (string: string) => {
        // Convert the string to lowercase and capitalize the first letter
        return string.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
    }

    const handleManageRole = (userId: string, userFirstName: string, userLastName: string, userRole: string) => {
        setSelectedUserFirstName(userFirstName);
        setSelectedUserLastName(userLastName);

        setFormState((formState) => ({
            ...formState, 
            'userId': userId,
            'role': userRole
        }));

        setFormStep(1);
    }

    const handleRoleClick = (role: string) => {
        setRemoveUser(false);
        setFormState((formState) => ({
            ...formState, 
            'role': role
        }));
    }

    const handleRemoveClick = () => {
        setFormState((formState) => ({
            ...formState, 
            'role': ''
        }));
        setRemoveUser(true);
    }

    const handleRemoveConfirm = () => {
        setRemoveUser(false);
        postFormData(
            commonData.token,
            `memorial/${memorialData.memorialId}/users`,
            formState,
            (response: any) => {
                setFormState(formData);
            },
            (error: any) => { // error
            }
        );

        setFormSuccess(true);

        setTimeout(() => {
            setFormStep(0);
        }, 1000)

        setTimeout(() => {
            setFormSuccessAnimation('slideOutRight');

            setTimeout(() => {
                setFormSuccess(false);
                setFormSuccessAnimation('slideInRight');
            }, 1000);
        }, 2000);
    }

    const handleGoBack = () =>{
        setShowPrompt(false);
    }

    const handleClickBack = () => {
        setFormStep(formStep - 1);
    }

    // Open modal
    const handleShow = () => {
        setShow(true);
    }

    // Close modal
    const handleClose = () => {
        modalClose();
        setShow(false);
    }

    // Post data
    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (removeUser) {
            setShowPrompt(true);
        } else {
            postFormData(
                commonData.token,
                `memorial/${memorialData.memorialId}/users`,
                formState,
                (response: any) => {
                    setFormState(formData);
                },
                (error: any) => { // error
                }
            );
    
            setFormSuccess(true);
    
            setTimeout(() => {
                setFormStep(0);
            }, 1000)
    
            setTimeout(() => {
                setFormSuccessAnimation('slideOutRight');
    
                setTimeout(() => {
                    setFormSuccess(false);
                    setFormSuccessAnimation('slideInRight');
                }, 1000);
            }, 2000);
        }
    }

    return (
        <>
            <div className="memorial-account-link" onClick={handleShow}>
                <img src="images/icon-people.svg" width={24} height={24} />
                <p>Manage roles</p>
            </div>

            <Modal show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    {(formStep === 1 && !formSuccess) &&
                        <div className="btn-modal-back" onClick={handleClickBack}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                    }

                    {formStep === 0 ?
                        <p className="modal-title"> Manage roles</p>
                    :
                        <p className="modal-title">Edit role</p>
                    }

                    <div className="btn btn-modal-close" onClick={handleClose} >
                        <img src="/images/modal-close-x.svg"/>
                    </div>
                </Modal.Header>

                <Modal.Body ref={modalRef} className="p-0">
                    <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')}`}>
                        {memorialUsers}
                    </div>

                    <div className={`p-3 pt-4 gap-3 form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')}`} style={{overflow: 'auto', scrollbarWidth: 'none'}}>

                        <img className="edit-role-avatar" src="/images/headshot-placeholder.svg" width={112} height={112} />

                        <div className="d-flex flex-column gap-2 mb-3">
                            <p className="edit-role-name">{selectedUserFirstName} {selectedUserLastName}</p>
                            <p className="edit-role-relation">Relationship TODO</p>
                        </div>

                        <div className="edit-role-tile" onClick={() => handleRoleClick('ADMIN')} style={{borderColor: `${!removeUser && formState.role === 'ADMIN' ? '#FFFFFF' : '#40626F'}`}}>
                            <p className="edit-role-title">Admin</p>
                            <p className="edit-role-desc">Can add, edit and approve content created by others.</p>

                            <div className="edit-role-radio">
                                <img src="/images/check-tick.svg" style={{display: `${!removeUser && formState.role === 'ADMIN' ? 'block' : 'none'}`}} />
                            </div>
                        </div>

                        <div className="edit-role-tile" onClick={() => handleRoleClick('CONTRIBUTOR')} style={{borderColor: `${!removeUser && formState.role === 'CONTRIBUTOR' ? '#FFFFFF' : '#40626F'}`}}>
                            <p className="edit-role-title">Contributor</p>
                            <p className="edit-role-desc">Can add life events to the memorial. All content must be approved by an admin.</p>

                            <div className="edit-role-radio">
                                <img src="/images/check-tick.svg" style={{display: `${!removeUser && formState.role === 'CONTRIBUTOR' ? 'block' : 'none'}`}} />
                            </div>
                        </div>

                        <div className="edit-role-tile" onClick={() => handleRoleClick('VIEWER')} style={{borderColor: `${!removeUser && formState.role === 'VIEWER' ? '#FFFFFF' : '#40626F'}`}}>
                            <p className="edit-role-title">Viewer</p>
                            <p className="edit-role-desc">Can only view the memorial.</p>

                            <div className="edit-role-radio">
                                <img src="/images/check-tick.svg" style={{display: `${!removeUser && formState.role === 'VIEWER' ? 'block' : 'none'}`}} />
                            </div>
                        </div>

                        <div className="edit-role-tile" onClick={handleRemoveClick} style={{borderColor: `${removeUser ? '#FFFFFF' : '#40626F'}`}}>
                            <p className="edit-role-title">Remove</p>
                            <p className="edit-role-desc">Remove this memorial from {selectedUserFirstName}’s profile.</p>

                            <div className="edit-role-radio">
                                <img src="/images/check-tick.svg" style={{display: `${removeUser ? 'block' : 'none'}`}} />
                            </div>
                        </div>

                        <button type="button" className="btn fl-btn-modal-bottom" onClick={handlePost}>Confirm changes</button>
                    </div>
                </Modal.Body>

                {formSuccess &&
                    <>
                        <div className={`modal-success-div d-flex align-items-center justify-content-center animate__animated animate__${formSuccessAnimation}`}>
                            <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
                                <img src="/images/fl-login-tick.svg" />
                                <div className="login-success-txt d-flex flex-column gap-1">
                                    <p>Role changed</p>
                                    <p>{selectedUserFirstName} was made a{formState.role === 'ADMIN' && 'n'} {toCamelCase(formState.role)}</p>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {showPrompt &&
                    <>
                        <div className="remove-user-prompt">
                            <div className="remove-user-prompt-body">
                                <div className="d-flex flex-column gap-2 mb-3">
                                    <p>Are you sure you want to remove {selectedUserFirstName}?</p>
                                    <p>{selectedUserFirstName} will no longer see this memorial on their profile. </p>
                                </div>

                                <div className="btn fl-btn-white w-100" onClick={handleRemoveConfirm}>Remove {selectedUserFirstName}</div>
                                <div className="btn fl-btn-dark w-100" onClick={handleGoBack}>Go back</div>
                            </div>
                        </div>
                    </>
                }
            </Modal>

            
        </>

    );
}