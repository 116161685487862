import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { LoginType, UserType } from "../AppType";
import { postFormData } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import { useNavigate } from "react-router-dom";
import { ModalCreateMemorial } from "./ModalCreateMemorial";

type ModalProps = {
    loggedOutState: boolean;
}

export const ModalCreateAccount: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [formStep, setFormStep] = useState<number>(-1);
    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>("modal-deactivate-scroll");
    const [formProgressHide, setFormProgressHide] = useState<string>("animate__animated animate__fadeInLeft animate__faster");
    const [isTransitioning, setIsTransitioning] = useState<Boolean>(false);

    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [formSuccessAnimation, setFormSuccessAnimation] = useState<string>("slideInRight");

    const refEmail = useRef<HTMLInputElement>(null!);
    const refFirstName = useRef<HTMLInputElement>(null!);

    const refBirthDateDay = useRef<HTMLInputElement>(null!);
    const refBirthDateMonth = useRef<HTMLInputElement>(null!);
    const refBirthDateYear = useRef<HTMLInputElement>(null!);

    const transformWrapperRef = useRef<ReactZoomPanPinchRef | null>(null);

    const [invalidBirthDate, setInvalidBirthDate] = useState<boolean>(false);
    const [invalidBirthDay, setInvalidBirthDay] = useState<boolean>(false);
    const [invalidBirthMonth, setInvalidBirthMonth] = useState<boolean>(false);
    const [invalidBirthYear, setInvalidBirthYear] = useState<boolean>(false);
    const invalidBorder: string = '3px solid #DC3545';

    const [pointerEvents, setPointerEvents] = useState<'all' | 'none'>('all');
    const [hasConnections, setHasConnections] = useState<boolean>(false);

    // State to keep track of the active element's ID
    const [activeId, setActiveId] = useState<string | null>(null);


    const baseUrlLogin: any = process.env.REACT_APP_BASE_LOGIN;
    const loginEndpoint = `${baseUrlLogin}`;

    const formData: UserType = {
        firstName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
        password: "",
        dateOfBirth: "",
        birthDateDay: "",
        birthDateMonth: "",
        birthDateYear: ""
    }

    const [formState, setFormState] = useState<UserType>(formData);

    const [formStateLogin, setFormStateLogin] = useState<LoginType>({
        email: 'aaa@acme.com',
        password: 'PWD'
    });

    const [newMemoriaDatal, setNewMemorialData] = useState({
        gender: "",
        relation: "",
        link: ""
    })

    const placeholderConnections = [
        {
            firstName: formState.firstName ? formState.firstName : 'Thomas',
            lastName: formState.lastName ? formState.lastName : 'Garrett',
            dob: '18 May 1965',
            relation: 'Brother',
            connection: 'Stan Garret'
        },
        {
            firstName: formState.firstName ? formState.firstName : 'Thomas',
            lastName: formState.lastName ? formState.lastName : 'Garrett',
            dob: '18 May 1965',
            relation: 'Father',
            connection: 'Annie Garret'
        },
        {
            firstName: formState.firstName ? formState.firstName : 'Thomas',
            lastName: formState.lastName ? formState.lastName : 'Garrett',
            dob: '18 May 1965',
            relation: 'Brother',
            connection: 'Stan Garret'
        },
        {
            firstName: formState.firstName ? formState.firstName : 'Thomas',
            lastName: formState.lastName ? formState.lastName : 'Garrett',
            dob: '18 May 1965',
            relation: 'Father',
            connection: 'Annie Garret'
        },
    ];

    const navigate = useNavigate();

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (show) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
      }, [show]);

    useEffect(() => {
    }, [props.loggedOutState]);

    // Disable DOM zooming to allow pinching on image
    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});
    
    const handleFormChange = (e: React.ChangeEvent<any>) => {
        if (e.target.type === "text") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));
        }

        if (e.target.type === "email") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));

            setFormStateLogin((formStateLogin) => ({
                ...formStateLogin, 
                [e.target.id]: e.target.value
            }));
        }

        if (e.target.type === "password") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));

            setFormStateLogin((formStateLogin) => ({
                ...formStateLogin, 
                [e.target.id]: e.target.value
            }));
        }
    }

    // Validation (day)
    const validateDay = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthDay(true);
            }

            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthDay(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthDay(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthDay(true);
            }

            return 'invalid';
        }
    }

    // Validation (month)
    const validateMonth = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthMonth(true);
            }
            
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthMonth(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthMonth(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthMonth(true);
            }

            return 'invalid';
        }
    }

    // Validation (year)
    const validateYear = (value: string): string => {

        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            if (formStep === 1) {
                setInvalidBirthYear(true);
            }
            
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            }

            return '000' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            } 

            return '00' + numberValue.toString();

        } else if (numberValue >= 100 && numberValue < 1000) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            }

            return '0' + numberValue.toString();

        } else if (numberValue >= 1000 && numberValue < 10000) {
            if (formStep === 1) {
                setInvalidBirthYear(false);
            }

            return numberValue.toString();

        } else {
            if (formStep === 1) {
                setInvalidBirthYear(true);
            }

            return 'invalid';
        }
    }

    // Form change (date fields)
    const handleFormChangeDate = (e: React.ChangeEvent<any>) => {

        if (e.target.type === "text") {

            // - - - - - Birth Date - - - - -
            if (e.target.id === "birthDateDay") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateDay(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refBirthDateMonth.current.focus();
                }
            }

            if (e.target.id === "birthDateMonth") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateMonth(e.target.value)
                }));

                if (e.target.value.length === 2) {
                    refBirthDateYear.current.focus();
                }
            }

            if (e.target.id === "birthDateYear") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateYear(e.target.value)
                }));
            }
        }
    }

    const passwordCreateGroup = document.getElementsByClassName('form-password-create');
    const passwordCreate = passwordCreateGroup[0] as HTMLInputElement;

    const handlePasswordCreateShow = () => {
        if (passwordCreate.type === "password") {
            passwordCreate.type = "text";
        } else {
            passwordCreate.type = "password";
        }
    }

    let conditionalStyle: React.CSSProperties = {}
    if (formStep === 2) {
        conditionalStyle = {
            backgroundColor: "#00222f",
            height: "calc(100dvh - (256px))"
        }
    }

    // - - - - - Change Form Step - - - - -
    const handleClickNext = () => {
        if (formStep === -1) {
            setFormProgressHide("animate__animated animate__fadeInRight animate__faster");

            setTimeout(() => {
                refFirstName.current.focus();
            }, 500);

            setFormStep(c => c + 1);

        } else if (formStep === 1) {
            if (formState.birthDateDay === "") {
                setInvalidBirthDay(true);
                setInvalidBirthDate(true);

            } else if (formState.birthDateMonth === "") {
                setInvalidBirthMonth(true);
                setInvalidBirthDate(true);
                
            } else if (formState.birthDateYear === "") {
                setInvalidBirthYear(true);
                setInvalidBirthDate(true);

            } else if (formState.birthDateDay != 'invalid' && formState.birthDateMonth != 'invalid' && formState.birthDateYear != 'invalid') {
                setFormState((formState) => ({
                    ...formState, 
                    birthDate: formState.birthDateYear + "-" + formState.birthDateMonth + "-" + formState.birthDateDay
                }));

                setFormStep(c => c + 1);
            } else {
                setInvalidBirthDate(true);
            }

        } else if (formStep === 2) {
            handlePost();

        } else {
            setFormStep(c => c + 1);
        }
        

        // if (formStep === 1) {
        //     setFormStep(2);
        // }
        
        // if (formStep === 2) {
        //     setFormStep(3);

        //     if (!hasConnections) {
        //         console.log('connections')
        //         setTimeout(() => {
        //             setFormStep(4);
        //         }, 3000);
        //     }
        // }
        
    }

    const handleClickBack = () => {
        setFormStep(formStep - 1);

        if (formStep === 1) {
            setTimeout(() => {
                refEmail.current.focus();
            }, 500);
        }

        if (formStep === 0) {
            setFormProgressHide("animate__animated animate__fadeOutLeft animate__faster");
        }

        setIsTransitioning(true);
        setTimeout(() => {
            setIsTransitioning(false);
        }, 500);
    }

    const handleClickLogin = () => {
        // document.getElementById('buttonLogin')?.click();
        handleClose();
        setFormStep(0);
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setTimeout(() => {
            setFormStep(-1);
        }, 1000);
    }

    const handlePost = async () => {
        // e.preventDefault();

        await postFormData(
            commonData.token,
            'user',
            formState,
            (response: any) => { // success
            },
            (error: any) => { // error
            }
        );

        setFormSuccess(true);

        await postLoginData(loginEndpoint, formStateLogin,
            (response:any) => {
                console.log("TOKEN", response);
            },
            (response:any) => {
                console.log("FAILED",response);
        });

        setTimeout(() => {
            navigate('/');
            setFormState(formData);
            setFormStep(-1);
            handleClose();
            setFormSuccess(false);
        }, 2000);

    }

    const postLoginData = async (endpoint: string, data: any, success: any, failure: any) => {

        console.log("LOGIN DATA", JSON.stringify(data));
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setCommonData(await response.json());
            } else {
                failure("Login Failed", response);
            }
        } catch (error) {
            failure(error);
        }
    }

    const handleSkip = () => {
        navigate('/');
    }

    return (
        <>
            {props.loggedOutState ?
                <button className="btn fl-btn-large mt-3" onClick={handleShow}>Create account</button> :
                <span onClick={handleShow} style={{whiteSpace: "nowrap", textDecoration: "underline", cursor: "pointer"}}>{ t("Login.Account.createAccount", {ns: "components"}) }</span>
            }

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>

                <Modal.Header className={`${formStep === 4 ? 'py-3' : ''}`} style={{justifyContent: `${formStep === -1 ? 'start' : 'center'}`, background: `${formStep === -1 ? '#001b26' : ''}`}}>
                    {formStep !== -1 &&
                        <div className={`btn-modal-back ${isTransitioning ? 'pe-none' : ''}`} onClick={handleClickBack}>
                            <img src="/images/modal-back-chev.svg" />
                        </div>
                    }

                    <p className="modal-title logo">forever loved</p>


                    {/* {formStep === 3 &&
                        (hasConnections ?
                            <p className="modal-title">Existing entries</p>
                        :
                            <p className="modal-title" style={{height: "22px"}}></p>
                        )
                    } */}

                    {/* {formStep === 4 &&
                        <div className="d-flex flex-column">
                            <p className="modal-title">Select whose memorial<br/>you’d like to build</p>
                        </div>
                    }

                    */}

                    <div className="btn btn-modal-close" onClick={handleClose}>
                        <img src="/images/modal-close-x.svg"/>
                    </div> 
                </Modal.Header>

                <Modal.Body>

                    {formStep >= 0 &&
                        <div className={`form-progress form-progress-absolute ${formProgressHide}`}>
                            <img src={`/images/fl-progress-heart.svg`} className={`form-progress-current form-progress-${formStep}`} />
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                            <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                        </div>
                    }

                    <div className="form-container">

                        {/* -1: Email */}
                        <div className={`form-screen form-content form-content-${formStep === -1 ? 'current' : (formStep > -1 ? 'prev' : 'next')}`} style={{padding: "32px 16px 16px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Keep their<br/>stories alive</p>
                                </div>
                                
                                <div className="login-form">
                                    <div>
                                        <label htmlFor="email" className="form-label">{ t("Login.Form.Email.label", {ns: "components"}) }</label>
                                        <input ref={refEmail} id="email" name="email" type="email" onChange={handleFormChange} className="form-control" placeholder="name@domain.com" required autoFocus />
                                    </div>
                                </div>

                                <button className="btn fl-btn-large w-100 mt-4" onClick={handleClickNext}>Next</button>
                            </div>
                        </div>

                        {/* 0: Name */}
                        <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep > 0 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>What's your name?</p>
                                </div>
                                
                                <div className="login-form">
                                    <div className="mb-4">
                                        <label htmlFor="firstName" className="form-label">First Name</label>
                                        <input ref={refFirstName} id="firstName" name="firstName" type="text"onChange={handleFormChange} className="form-control" placeholder="Enter your own first name" required />
                                    </div>

                                    <div>
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <input id="lastName" name="lastName" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter your own last name" required />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 1: DoB */}
                        <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep > 1 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>When were you born?</p>
                                </div>
                                
                                <div className="login-form">
                                    <div>
                                        <label htmlFor="birthDateDay" className="form-label">Date of Birth</label>
                                        <div className="d-flex gap-3">
                                            <input ref={refBirthDateDay} id="birthDateDay" name="birthDateDay" type="text" maxLength={2} onChange={handleFormChangeDate} className="form-control" placeholder="DD" style={{border: invalidBirthDay ? invalidBorder : ""}} />
                                            <input ref={refBirthDateMonth} id="birthDateMonth" name="birthDateMonth" type="text" maxLength={2} onChange={handleFormChangeDate} className="form-control" placeholder="MM" style={{border: invalidBirthMonth ? invalidBorder : ""}} />
                                            <input ref={refBirthDateYear} id="birthDateYear" name="birthDateYear" type="text" maxLength={4} onChange={handleFormChangeDate} className="form-control" placeholder="YYYY" style={{border: invalidBirthYear ? invalidBorder : ""}} />
                                        </div>
                                    </div>
                                </div>

                                {invalidBirthDate &&
                                    <div className="invalid-form-entry animate__animated animate__zoomIn">Please enter a valid date</div>
                                }
                            </div>
                        </div>

                        {/* 2: Password */}
                        <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep > 2 ? 'prev' : 'next')}`} style={{padding: "80px 16px 16px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Create a password</p>
                                </div>
                                
                                <div className="login-form">
                                    <div className="mb-2" style={{position: "relative"}}>
                                            <label htmlFor="password" className="form-label">{ t("Login.Form.Password.label", {ns: "components"}) }</label>
                                            <input id="password" name="password" type="password" onChange={handleFormChange} className="form-control form-password-create" placeholder="Enter a strong password" required />
                                            <img src="/images/fl-eye.svg" onClick={handlePasswordCreateShow} style={{position: "absolute", top: "63%", right: "16px", cursor: "pointer"}}/>
                                        </div>
                                        <p className="input-subtext">Must contain: 1 Upper Case Letter, 1 Number</p>
                                    </div>
                            </div>
                        </div>

                        {/* 3: new or existing tree */}
                        {/* <div className={`form-screen form-content form-content-${formStep === 3 ? 'current' : (formStep > 3 ? 'prev' : 'next')} justify-content-center`} style={conditionalStyle}>
                            {hasConnections ?
                                <div className="h-100 p-3">
                                    <div className="existing-connections-label">
                                        <img src="/images/icon-puzzle.svg" />
                                        <div className="label-copy">
                                            <p>We found a few matches</p>
                                            <p>There are some existing entries on Forever Loved that match your details.</p>
                                        </div>
                                    </div>

                                    <p className="existing-connections-heading">Please select the entry that is you</p>

                                    <div className="existing-connections-wrapper">
                                        {placeholderConnections.map((placeholder, index) => {
                                            return (
                                                <div key={index} className="existing-connection-tile">
                                                    <div className={`leaf-tile-select`}>
                                                        <img src="/images/check-tick.svg" alt="" />
                                                    </div>

                                                    <img src="/images/headshot-placeholder.svg" width={64} height={64} />
                                                    <p className="connection-name">{placeholder.firstName}<br/>{placeholder.lastName}</p>
                                                    <p className="connection-date">{placeholder.dob}</p>
                                                    <p className="connection-link">{placeholder.relation} to <b>{placeholder.connection}</b></p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            :
                                <div className="d-flex flex-column align-items-center">
                                    <p className="modal-title">Select whose memorial<br/>you’d like to build first</p>
                                </div>
                            }
                        </div> */}

                        {/* 4: family tree */}
                        {/* <div className={`form-screen form-content form-content-${formStep === 4 ? 'current' : (formStep > 4 ? 'prev' : 'next')} justify-content-center`} style={conditionalStyle}>
                            <TransformWrapper ref={transformWrapperRef} onPanning={handlePanning} onPanningStop={handlePanningStop} centerOnInit={true} doubleClick={{disabled: true}}>
                                <TransformComponent>
                                    <div id="deactivateZoom" className="create-account-family-tree">
                                        
                                        <div className="d-flex flex-wrap-reverse">
                                            <div className="tile tile-parent" style={{opacity: activeId !== null && (activeId !== 'fatherExtParent' && activeId !== 'fatherExtSibling') ? 0.2 : 1}}>
                                                <div className="text-center w-100 h-100" onClick={(e) => handleTileClick(e, 'Male', 'father', 'Origin')} style={{alignContent: "center", pointerEvents}}>
                                                    <p>Father</p>
                                                </div>

                                                {activeId === 'fatherExtParent' ?
                                                    <div id="fatherExtParent"  className="family-tree-ext top active" onClick={(e) => handleTileExpand(e, 'fatherExtParent')} style={pointerEventsStyle}>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Male', 'grandfather', 'Father')} style={{top: "-55px", left: "-80px", pointerEvents}}>
                                                            <p>Grandfather</p>
                                                        </div>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Female', 'grandmother', 'Father')} style={{top: "-55px", right: "-80px", pointerEvents}}>
                                                            <p>Grandmother</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="family-tree-ext top" onClick={(e) => handleTileExpand(e, 'fatherExtParent')} style={{...pointerEventsStyle, opacity: activeId !== null && activeId !== 'fatherExtParent' ? 0.2 : 1}}></div>
                                                }

                                                {activeId === 'fatherExtSibling' ?
                                                    <div className="family-tree-ext left active" onClick={(e) => handleTileExpand(e, 'fatherExtSibling')} style={pointerEventsStyle}>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Male', 'uncle', 'Father')} style={{top: "-170px", pointerEvents}}>
                                                            <p>Uncle</p>
                                                        </div>
                                                        <div id="fatherExtSibling" className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Female', 'aunt', 'Father')} style={{top: "-58px", pointerEvents}}>
                                                            <p>Aunt</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="family-tree-ext left" onClick={(e) => handleTileExpand(e, 'fatherExtSibling')} style={{...pointerEventsStyle, opacity: activeId !== null && activeId !== 'fatherExtSibling' ? 0.2 : 1}}></div>
                                                }
                                            </div>

                                            <div className="tree-link" style={{opacity: activeId !== null ? 0.2 : 1}}>
                                                <img className="link-down" src="/images/tree-link-down.svg" />
                                                <img className="link-across parent" src="/images/tree-link-across.svg" />
                                                <img className="link-dot parent" src="/images/tree-link-dot.svg" />
                                            </div>

                                            <div className="tile tile-parent" style={{opacity: activeId !== null && (activeId !== 'motherExtParent' && activeId !== 'motherExtSibling') ? 0.2 : 1}}>
                                                <div className="text-center w-100 h-100" onClick={(e) => handleTileClick(e, 'Female', 'mother', 'Origin')} style={{alignContent: "center", pointerEvents}}>
                                                    <p>Mother</p>
                                                </div>

                                                {activeId === 'motherExtParent' ?
                                                    <div id="motherExtParent" className="family-tree-ext top active" onClick={(e) => handleTileExpand(e, 'motherExtParent')} style={pointerEventsStyle}>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Male', 'grandfather', 'Mother')} style={{top: "-55px", left: "-80px", pointerEvents}}>
                                                            <p>Grandfather</p>
                                                        </div>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Female', 'grandmother', 'Mother')} style={{top: "-55px", right: "-80px", pointerEvents}}>
                                                            <p>Grandmother</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="family-tree-ext top" onClick={(e) => handleTileExpand(e, 'motherExtParent')} style={{...pointerEventsStyle, opacity: activeId !== null && activeId !== 'motherExtParent' ? 0.2 : 1}}></div>
                                                }

                                                {activeId === 'motherExtSibling' ?
                                                    <div className="family-tree-ext right active" onClick={(e) => handleTileExpand(e, 'motherExtSibling')} style={pointerEventsStyle}>
                                                        <div id="motherExtSibling" className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Male', 'uncle', 'Mother')} style={{top: "-58px", pointerEvents}}>
                                                            <p>Uncle</p>
                                                        </div>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Female', 'aunt', 'Mother')} style={{top: "-170px", pointerEvents}}>
                                                            <p>Aunt</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="family-tree-ext right" onClick={(e) => handleTileExpand(e, 'motherExtSibling')} style={{...pointerEventsStyle, opacity: activeId !== null && activeId !== 'motherExtSibling' ? 0.2 : 1}}></div>
                                                }
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            <div id="origin" className="tile tile-origin user" style={{opacity: activeId !== null && (activeId !== 'originExtSiblingLeft' && activeId !== 'originExtSiblingRight' && activeId !== 'partnerExtParentLeft' && activeId !== 'partnerExtParentRight') ? 0.2 : 1}}>
                                                <div className="d-flex flex-column align-items-center gap-2">
                                                    <img src="/images/headshot-placeholder.svg" />
                                                    <p>You</p>
                                                </div>
                                                
                                                {(activeId === 'originExtSiblingLeft' || activeId === 'partnerExtParentLeft') ?
                                                    <div className="family-tree-ext left active" onClick={(e) => handleTileExpand(e, 'originExtSiblingLeft')} style={pointerEventsStyle}>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, '', 'partner', 'Origin')} style={{top: "-115px", right: "-135px", borderColor: '#801F4F', pointerEvents}}>
                                                            <p>Partner</p>

                                                            {activeId === 'partnerExtParentLeft'  ?
                                                                <div id="partnerExtParentLeft"  className="family-tree-ext top t-2 active" onClick={(e) => handleTileExpand(e, 'partnerExtParentLeft')} style={pointerEventsStyle}>
                                                                    <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Male', "partner's father", 'Partner')} style={{top: "-55px", left: "-80px", borderColor: '#801F4F', pointerEvents}}>
                                                                        <p>Partner's Father</p>
                                                                    </div>
                                                                    <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Female', "partner's mother", 'Partner')} style={{top: "-55px", right: "-80px", borderColor: '#801F4F', pointerEvents}}>
                                                                        <p>Partner's Mother</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="family-tree-ext top t-2" onClick={(e) => handleTileExpand(e, 'partnerExtParentLeft')} style={{...pointerEventsStyle, opacity: activeId === 'originExtSiblingLeft' || activeId === 'partnerExtParentLeft' ? 1 : 0.2}}></div>
                                                            }

                                                        </div>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Male', 'brother', 'Origin')} style={{top: "-180px", pointerEvents}}>
                                                            <p>Brother</p>
                                                        </div>
                                                        <div id="originExtSiblingLeft" className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Female', 'sister', 'Origin')} style={{top: "-58px", pointerEvents}}>
                                                            <p>Sister</p>
                                                        </div>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, '', 'friend', 'origin')} style={{top: "-115px", right: "135px", borderColor: '#004A67', pointerEvents}}>
                                                            <p>Friend</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="family-tree-ext left" onClick={(e) => handleTileExpand(e, 'originExtSiblingLeft')} style={{...pointerEventsStyle, opacity: activeId !== null && activeId !== 'originExtSiblingLeft' ? 0.2 : 1}}></div>
                                                }

                                                {(activeId === 'originExtSiblingRight' || activeId === 'partnerExtParentRight') ?
                                                    <div className="family-tree-ext right active" onClick={(e) => handleTileExpand(e, 'originExtSiblingRight')} style={pointerEventsStyle}>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, '', 'partner', 'Origin')} style={{top: "-115px", right: "135px", borderColor: '#801F4F', pointerEvents}}>
                                                            <p>Partner</p>

                                                            {activeId === 'partnerExtParentRight' ?
                                                                <div id="partnerExtParentRight"  className="family-tree-ext top t-2 active" onClick={(e) => handleTileExpand(e, 'partnerExtParentRight')} style={pointerEventsStyle}>
                                                                    <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Male', "partner's father", 'Partner')} style={{top: "-55px", left: "-80px", borderColor: '#801F4F', pointerEvents}}>
                                                                        <p>Partner's Father</p>
                                                                    </div>
                                                                    <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Female', "partner's mother", 'Partner')} style={{top: "-55px", right: "-80px", borderColor: '#801F4F', pointerEvents}}>
                                                                        <p>Partner's Mother</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="family-tree-ext top t-2" onClick={(e) => handleTileExpand(e, 'partnerExtParentRight')} style={{...pointerEventsStyle, opacity: activeId === 'originExtSiblingRight' || activeId === 'partnerExtParentRight' ? 1 : 0.2}}></div>
                                                            }

                                                        </div>
                                                        <div id="originExtSiblingRight" className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Male', 'brother', 'Origin')} style={{top: "-58px", pointerEvents}}>
                                                            <p>Brother</p>
                                                        </div>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, 'Female', 'sister', 'Origin')} style={{top: "-180px", pointerEvents}}>
                                                            <p>Sister</p>
                                                        </div>
                                                        <div className="tile tile-extended" onClick={(e) => handleTileClick(e, '', 'friend', 'Origin')} style={{top: "-115px", right: "-135px", borderColor: '#004A67', pointerEvents}}>
                                                            <p>Friend</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="family-tree-ext right" onClick={(e) => handleTileExpand(e, 'originExtSiblingRight')} style={{...pointerEventsStyle, opacity: activeId !== null && activeId !== 'originExtSiblingRight' ? 0.2 : 1}}></div>
                                                }
                                            </div>
                                        </div>

                                        <div className="d-flex" style={{opacity: activeId !== null ? 0.2 : 1}}>
                                            <div className="tile tile-child" onClick={(e) => handleTileClick(e, 'Male', 'son', 'Origin')} style={pointerEventsStyle}>
                                                <p>Son</p>
                                            </div>
                                            <div className="tree-link">
                                                <img className="link-down" src="/images/tree-link-down.svg" />
                                                <img className="link-across child" src="/images/tree-link-across.svg" />
                                                <img className="link-dot child" src="/images/tree-link-dot.svg" />
                                            </div>
                                            <div className="tile tile-child" onClick={(e) => handleTileClick(e, 'Female', 'daughter', 'Origin')} style={pointerEventsStyle}>
                                                <p>Daughter</p>
                                            </div>
                                        </div>

                                        <div className="d-flex" style={{opacity: activeId !== null ? 0.2 : 1}}>
                                            <div className="tile tile-child pet" onClick={(e) => handleTileClick(e, '', 'pet', 'Origin')} style={pointerEventsStyle}>
                                            <div className="d-flex flex-column align-items-center gap-1">
                                                    <img src="/images/dog-paw-icon.svg" />
                                                    <p>Pet</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TransformComponent>
                            </TransformWrapper>
                        </div> */}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="d-flex flex-column align-items-center w-100">
                        {formStep === -1 ?
                            <p className="fl-txt-login-subheading mt-3 float-center">I already have an account. <span onClick={handleClickLogin} style={{textDecoration: "underline", cursor: "pointer"}}>Login</span></p>
                            :
                            <>
                            {formStep === 2 ?
                                <button className="btn fl-btn-large w-100" onClick={handleClickNext}>Create account</button>
                                :
                                <button className="btn fl-btn-large w-100" onClick={handleClickNext}>Next</button>
                            }
                            </>
                            
                        }
                    </div>

                    {/* {hasConnections ?
                        (formStep === 3 && 
                            <button className="btn fl-btn-large w-100" onClick={handleConfirmConnections}>Confirm</button>
                        )
                    :
                        ((formStep === 3) && 
                            <div className="d-flex flex-column align-items-center w-100">
                                <p className="fl-txt-login-subheading mt-3 float-center" onClick={handleSkip} style={{textDecoration: "underline", cursor: "pointer"}}>Not right now</p>
                            </div>
                        )
                        <></>
                    }

                    {formStep === 4 &&
                        <div className="d-flex justify-content-center gap-2 w-100">
                            <img src="/images/pinch-zoom.svg" />
                            <p className="pinch-zoom-text">Pinch & zoom to crop</p>
                        </div>
                    } */}
                </Modal.Footer>

                {formSuccess &&
                    <>
                        <div className={`modal-success-div d-flex align-items-center justify-content-center animate__animated animate__${formSuccessAnimation}`}>
                            <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
                                <img src="/images/fl-login-tick.svg" />
                                <div className="login-success-txt d-flex flex-column gap-1">
                                    <p>Account Created</p>
                                    <p>Welcome to Forever Loved</p>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal>

            <ModalCreateMemorial emptyState={false} fromAccountCreate={true} gender={newMemoriaDatal.gender} relation={newMemoriaDatal.relation} link={newMemoriaDatal.link} />
        </>
    );
}