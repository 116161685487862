import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css"
import { ASSET_URL, debug, deleteRecord, fetchRecords, postFormData } from "../../utils/AppUtil";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { PageResultType, MemorialType } from "../AppType";

type ModalProps = {
    handleOpen: boolean;
    closeModal: () => void;
}

export const LinkMemorial: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [selected, setSelected] = useState<boolean>(false);

    // Modal states
    const [show, setShow] = useState(false);

    const [memorials, setMemorials] = useState<React.ReactElement[]>();

    useEffect(() => {
        fetchRecords(commonData.token, 'memorial/homepage', processMemorialData)
        .catch((error) => {
            debug('fetchRecords error: ' + error, 'Family Tree - Memorials');
        });
    }, []);

    const processMemorialData = (responseJson: PageResultType) => {
        setMemorials(buildMemorialsJSX(responseJson));
    }

    const buildMemorialsJSX = (response: PageResultType): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        let pictureSrc: string = '/images/headshot-placeholder.svg';

        response.data.forEach((memorial: MemorialType) => {

            if (memorial.memorialPictureId) pictureSrc = `${ASSET_URL}/public/picture/${memorialData.memorialPictureId}`;

            result.push(
                <div className="family-tree-search-item" onClick={handleSelect}>
                    <div className="search-item-content">
                        <img src={pictureSrc} />

                        <div className="search-item-text">
                            <p>{memorial.firstName} {memorial.lastName}</p>
                            <p>{memorial.birthYear} - {memorial.deathYear}</p>
                        </div>
                    </div>

                    <div className={`search-item-radio ${selected && 'selected'} my-auto`}>
                        <div className="radio-selected"></div>
                    </div>

                </div>
            );
            key++;
        });

        return result;
    }

    useEffect(() => {
        setShow(props.handleOpen);
    }, [props.handleOpen]);

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        props.closeModal();
    }

    const handleSelect = () => {
        setSelected(current => !current);
    }


    return (
        <>
            <Modal show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <p className="modal-title">Link a Memorial</p>
                    
                    <div className={`btn btn-modal-close`} onClick={handleClose} >
                        <img src="/images/modal-close-x.svg"/>
                    </div>
                </Modal.Header>

                <Modal.Body className="p-3">

                <div className="input-group">
                    <input className="family-tree-search-input" type="text" placeholder="Search all memorials" />
                    <div className="search-icon-wrapper">
                        <img src="/images/fl-icon-search.svg" />
                    </div>
                </div>

                    <p className="fl-txt-home-subheading mt-4 mb-3">Your Memorials</p>

                    <div className="d-flex flex-column gap-3">
                        {memorials}
                    </div>
                    
                </Modal.Body>
            </Modal>
        </>
    );
}