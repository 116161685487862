import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { GetUserType, PictureGalleryType, PictureUpdateType, PictureUploadType, UserPictureUploadType, UserUpdateType } from "../AppType";
import { ASSET_URL, debug, fetchImage, fetchRecords, postFormData, postFormPictureFormData } from "../../utils/AppUtil";
import { Modal } from "react-bootstrap";
import { useSpring} from '@react-spring/web';
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'
import AvatarEditor from 'react-avatar-editor';
import imageCompression from "browser-image-compression";
import { useTranslation } from "react-i18next";
import 'animate.css';

const useGesture = createUseGesture([dragAction, pinchAction]);

export const MyNetwork: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Modal handlers
    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [formStep, setFormStep] = useState<number>(0);
    const [activeTab, setActiveTab] = useState<string>('SUGGESTED');
    const [noSuggestions, setNoSuggestions] = useState<boolean>(false);
    const [noExisting, setNoExisting] = useState<boolean>(false);

    // Disable vertical scrolling on body when modal is open
    const useLockBodyScroll = (showModal: boolean, modalRef: MutableRefObject<HTMLElement | null>): void => {
        useEffect(() => {
            const preventScroll = (e: TouchEvent) => {
                if (modalRef.current && modalRef.current.contains(e.target as Node)) {
                    return; // Allow scrolling inside the modal
                }

                e.preventDefault(); // Prevent scrolling outside the modal (i.e., body scroll)
            };

            if (showModal) {
                document.body.style.position = 'fixed'; // Fixes the body position
                window.addEventListener('touchmove', preventScroll, { passive: false });
            } else {
                document.body.style.position = ''; // Revert body positioning
                window.removeEventListener('touchmove', preventScroll);
            }

            return () => {
                // Cleanup when modal is closed
                document.body.style.position = '';
                window.removeEventListener('touchmove', preventScroll);
            };
        }, [showModal, modalRef]);
    };

    const modalRef = useRef<HTMLDivElement | null>(null);
    useLockBodyScroll(show, modalRef);

    const handleToggleTab = (tab: string) => {
        setActiveTab(tab);
    }

    // Open modal
    const handleShow = () => {
        setShow(true);
    }

    // Close modal
    const handleClose = () => {
        setShow(false);
        setModalSuccess(false);
    }

    return (
        <>
            <div className="my-account-tile" onClick={handleShow}>
                <img src="images/img-my-network.svg" />
                <p>My network</p>
            </div>

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <div className="btn-modal-back" onClick={handleClose}>
                        <img src="/images/modal-back-chev.svg" />
                    </div>
                    <p className="modal-title">My network</p>
                </Modal.Header>

                <Modal.Body ref={modalRef} className="p-0">

                    <div className="my-network-tabs">
                        <div className={`tab-btn ${activeTab === 'SUGGESTED' && 'active'}`} onClick={() => handleToggleTab('SUGGESTED')}>
                            <img src="/images/fl-person-plus.svg" />
                            <p>Suggested</p>
                        </div>

                        <div className={`tab-btn  ${activeTab === 'EXISTING' && 'active'}`} onClick={() => handleToggleTab('EXISTING')}>
                            <img src="/images/icon-people.svg" />
                            <p>Existing network</p>
                        </div>
                    </div>

                    <div className="search-input input-group m-3">
                        <input type="text" className="form-control" placeholder="Search for a user" />
                        <span className="input-group-text">
                            <img src="/images/fl-icon-search.svg" width={16} height={16} />
                        </span>
                    </div>


                    {activeTab === 'SUGGESTED' &&
                        <>
                            <div className="card card-find-connections m-3">
                                <img src="/images/img-find-connections.png" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <div className="card-text d-flex flex-column gap-2 mb-4">
                                        <p className="title">Be the friend who cares</p>
                                        <p className="subtitle">Grow your Forever Loved network to share memories and be reminded on special days.</p>
                                    </div>
                                    <button className="fl-btn-white-large">Find your contacts</button>
                                </div>
                            </div>

                            <p className="account-content-heading fl-border-bottom">Suggested</p>

                            {noSuggestions ?
                                <div className="no-suggestions">
                                    <p>No suggestions right now</p>
                                    <p>Search for a friend or find contacts from your device</p>
                                </div>
                                :
                                <>
                                    <div className="my-network-list-item">
                                        <img className="profile-img" src="/images/tree-avatar-placeholder.svg" />
                                        <div className="d-flex flex-column gap-1">
                                            <p className="name">John Doe</p>
                                            <p className="status">Added you</p>

                                            <div className="cta">
                                                <img className="add-icon" src="/images/fl-person-plus.svg" />
                                                <p>Add back</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }

                    {activeTab === 'EXISTING' &&
                        <>
                            <p className="account-content-heading fl-border-bottom">Existing network</p>

                            {noExisting ?
                                <div className="no-suggestions">
                                    <p className="title">Your network is currently empty</p>
                                </div>
                                :
                                <>
                                    <div className="my-network-list-item">
                                        <img className="profile-img" src="/images/tree-avatar-placeholder.svg" />
                                        <div className="d-flex flex-column gap-1">
                                            <p className="name">Jane Doe</p>
                                            <p className="status">Added</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }

                </Modal.Body>
            </Modal>
        </>

    );
}