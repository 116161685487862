import { Page404 } from './screens/Page404';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MemorialJourney } from './screens/MemorialJourney';
import { Login } from './screens/Login';
import './App.css';
import { useEffect } from 'react';

// https://stackoverflow.com/questions/56028635/passing-usestate-as-props-in-typescript

function App() {

  return (
    <div className="App">
        <Router basename="">

          <Routes>
            <Route path="/" element={<MemorialJourney />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
          
        </Router>
    </div>
  );
}

export default App;
